import React from 'react'
import { Link } from 'react-router-dom'
import { dateFormat } from '../../../../itrust_common/components/HelperFunctions'
import DateFormatView from '../../../../itrust_common/components/others/DateFormatView'

export default function AdvertisementWrap(props) {
  const { isPopupScreen, addOnAdvDetail, organisationName, onActivate } = props

  return (
    <div className={`${isPopupScreen ? 'overflow-auto pop-up-adv' : 'pt-8'}`}>
      <div className={`bg-white ${isPopupScreen ? 'px-7 py-5 shadow-sm' : 'p-7'}`}>
        <div className='row'>
          <div className={`${isPopupScreen ? 'order-2 col-12 px-0' : 'col-6'}`}>
            <h4 className='d-flex align-items-center mb-6'>
              {addOnAdvDetail.title}
              <a href={addOnAdvDetail.redirectTo} target='_blank' className='text-primary ml-3 font-size-24'>
                <i className="las la-external-link-alt"></i>
              </a>
            </h4>
            <ul className={`pl-6 ${!isPopupScreen && 'font-size-20'}`}>
              {addOnAdvDetail.features.map(feature => <li className='pb-5'>{feature}</li>)}
            </ul>
            {onActivate ?
              <button className='btn btn-primary' onClick={onActivate}> Activate Now</button> :
              <Link className={`btn btn-primary ${isPopupScreen ? 'w-100' : 'ml-6'}`} to={`/payment/add-ons`}>
                Activate Now
              </Link>
            }
          </div>
          <div className={`${isPopupScreen ? 'order-1 col-12 mb-6 px-0' : 'col-6'} position-relative`}>
            <img src={isPopupScreen ? addOnAdvDetail.isPopupScreenAddOnImg : addOnAdvDetail.imageAddOn} className='w-100' />
            {organisationName &&
              <p className={`text-white position-absolute mb-0 ${isPopupScreen ? 'two-way-popup-image-text font-size-12' : 'two-way-image-text font-size-18'}`}>
                <b>{organisationName}</b>:<br />
                You have an appointment scheduled<br />
                for <b><DateFormatView date={new Date()} /></b> at 4pm.<br />
                <span className='font-size-12 py-2'>SMS TEXT MESSAGE - JUST NOW</span>
              </p>
            }
          </div>
        </div>
      </div>
      {!isPopupScreen && <div className='mt-6 px-6'>
        <h5>Features</h5>
        <img src={addOnAdvDetail.imageFeatures} className='w-100 mt-6' />
      </div>}
    </div>)
}
