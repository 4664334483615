import React, { useEffect, useState } from 'react'
import PreliminaryBinocularForm from './edit/PreliminaryBinocularForm'
import HpiForm from './edit/HpiForm'
import AdditionalTestForm from './edit/AdditionalTestForm'
import AttachedDocForm from './edit/AttachedDocForm'
import ExternalInternalForm from './edit/ExternalInternalForm'
import ProcedureImpressionPlanForm from './edit/ProcedureImpressionPlanForm';
import RefractionContactLensForm from './edit/RefractionContactLensForm';
import FinalizePrescriptionForm from './edit/FinalizePrescriptionForm';
import SocialHistoryForm from '../../../containers/authorised/patientPartials/SocialHistoryPartial'
import MedicalHistoryForm from '../../../containers/authorised/patientPartials/MedicalHistoryPartial'
import ReviewOfSystemForm from '../../../containers/authorised/patientPartials/ReviewOfSystemPartial'
import Referral from '../../../containers/authorised/referral/Referral'
import MedicalRx from '../../../containers/authorised/medicalRx/MedicalRx';
import OldValuesWrapper from './oldValues/OldValuesWrapper';
import UserCam from '../../../support/utils/UserCam';
import { connect, useDispatch } from 'react-redux'
import Overview from './Overview'
import { submit } from 'redux-form'
import { patientReportPreview } from '../../../redux/actions/patient/patientReportActions'
import { checkIsVisible } from '../../../itrust_common/components/HelperFunctions'

function ExamTabsContent(props) {
  const { handleScroll, currentUser, activeTab, overviewName, exam, store, onSecurFax, emailPrescriptionReportSend, onSubmit, accordionToggle, examViewModern, showExternalInternalForm } = props

  const { patient_attributes, template, patient_id, template: { hpi, social_history, medical_history, ros, preliminary_binocular_testing, refraction_contact_lens_exam, external_internal_exam, additional_tests, procedures_and_impressions, finalize_prescription, attached_documents }, } = exam
  const dispatch = useDispatch()

  const handlePrintPrescription = async () => {
    await dispatch(submit("examFinalizePrescriptionForm"));
    var res = await dispatch(patientReportPreview(exam.patient_id, { report_type: "prescriptions", object_id: exam.id }))
    window.open(res.value.data.filepath, '_blank')
  }

  const scrollClass = accordionToggle ? 'exam-header-open' : 'exam-header-collapse'

  return (
    <div id="examForm-scroll" className={`${accordionToggle ? 'middle-container-modal-2 mt-5' : 'middle-container-modal'} ${scrollClass} px-5 mb-9`}>
      <div className="tab-content overflow-auto" onScroll={handleScroll} style={{ height: '800px' }}>
        {(!examViewModern && activeTab === 1) &&
          <div className="tab-pane active exam-content-tabs" id="overview" role="tabpanel" aria-labelledby="overview-tab" data-tab-id="1">
            <Overview exam={exam} store={store} patient={patient_attributes} showExternalInternalForm={showExternalInternalForm} />
          </div>
        }
        {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) &&
          <>
            {((examViewModern && hpi && checkIsVisible(hpi.visible)) || activeTab === 2) &&
              <div className="tab-pane active" id="history-present-illness" role="tabpanel" aria-labelledby="history-present-illness-tab" data-tab-id="2">
                <HpiForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }
            {((examViewModern && social_history && checkIsVisible(social_history.visible)) || activeTab === 3) &&
              <div className="tab-pane active" id="social-history" role="tabpanel" aria-labelledby="social-history-tab" >
                {examViewModern && <h5 className='exam-content-tabs' data-tab-id="3">Social History</h5>}
                <SocialHistoryForm patientId={patient_id} examId={exam.id} />
              </div>
            }
            {((examViewModern && medical_history && checkIsVisible(medical_history.visible)) || activeTab === 4) &&
              <div className="tab-pane active" id="medical-history" role="tabpanel" aria-labelledby="medical-history-tab" >
                {examViewModern && <h5 className='exam-content-tabs' data-tab-id="4">Medical History</h5>}
                <MedicalHistoryForm patientId={patient_id} examId={exam.id} />
              </div>
            }
            {((examViewModern && ros && checkIsVisible(ros.visible)) || activeTab === 5) &&
              <div className="tab-pane active" id="ros" role="tabpanel" aria-labelledby="ros-tab" >
                {examViewModern && <h5 data-tab-id="5" className='mt-6 exam-content-tabs'> ROS</h5>}
                <ReviewOfSystemForm patientId={patient_id} examId={exam.id} />
              </div>
            }
            {((examViewModern && preliminary_binocular_testing && checkIsVisible(preliminary_binocular_testing.visible)) || activeTab === 6) &&
              <div className="tab-pane active" id="preliminary-binocular" role="tabpanel" aria-labelledby="preliminary-binocular-tab">
                {examViewModern && <h5 data-tab-id="6" className='mb-6 pt-9 exam-content-tabs'>Preliminary/ Binocular</h5>}
                <PreliminaryBinocularForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }
            {((examViewModern && refraction_contact_lens_exam && checkIsVisible(refraction_contact_lens_exam.visible)) || activeTab === 7) &&
              <div className="tab-pane active" id="refraction-contact-lens" role="tabpanel" aria-labelledby="refraction-contact-lens-tab" >
                {examViewModern && <h5 data-tab-id="7" className='my-6 exam-content-tabs'>Refraction/ Contact Lens</h5>}
                <RefractionContactLensForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }
            {((examViewModern && external_internal_exam && checkIsVisible(external_internal_exam.visible)) || activeTab === 8) &&
              <div className="tab-pane active" id="external-internal" role="tabpanel" aria-labelledby="external-internal-tab" >
                {examViewModern && <h5 data-tab-id="8" className='my-6 exam-content-tabs'>External/ Internal</h5>}
                {showExternalInternalForm && <ExternalInternalForm onSubmit={onSubmit} template={template} isTemplate={false} />}
              </div>
            }
            {((examViewModern && additional_tests && checkIsVisible(additional_tests.visible)) || activeTab === 9) &&
              <div className="tab-pane active" id="additional-tests" role="tabpanel" aria-labelledby="additional-tests-tab" >
                {examViewModern && <h5 data-tab-id="9" className='my-6 exam-content-tabs'>Additional Tests</h5>}
                <AdditionalTestForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }
            {((examViewModern && procedures_and_impressions && checkIsVisible(procedures_and_impressions.visible)) || activeTab === 10) &&
              <div className="tab-pane active" id="impression-plan" role="tabpanel" aria-labelledby="impression-plan-tab" >
                {examViewModern && <h5 data-tab-id="10" className='mt-6 exam-content-tabs'>Procedure Impression/Plan</h5>}
                <ProcedureImpressionPlanForm onSubmit={onSubmit} template={template} isTemplate={false} exam={exam} />
              </div>
            }
            {((examViewModern && finalize_prescription && checkIsVisible(finalize_prescription.visible)) || activeTab === 11) &&
              <div className="tab-pane active" id="finalize-prescription" role="tabpanel" aria-labelledby="finalize-prescription-tab" >
                {examViewModern && <h5 data-tab-id="11" className='my-6 exam-content-tabs'>Finalize Prescription</h5>}
                <FinalizePrescriptionForm onSubmit={onSubmit} exam={exam} onSecurFax={onSecurFax} emailPrescriptionReportSend={emailPrescriptionReportSend} handlePrintPrescription={handlePrintPrescription} template={template} isTemplate={false} />
              </div>
            }
            {((examViewModern && attached_documents && checkIsVisible(attached_documents.visible)) || activeTab === 12) &&
              <div className="tab-pane active" id="attached-docs" role="tabpanel" aria-labelledby="attached-docs-tab" >
                {examViewModern && <h5 data-tab-id="12" className='exam-content-tabs'>Attached Docs</h5>}
                <AttachedDocForm onSubmit={onSubmit} template={template} isTemplate={false} />
              </div>
            }
          </>
        }
      </div>

      <Referral exam={exam} store={store} />
      <MedicalRx onSubmitHook={() => onSubmit({ id: exam.id })} patientId={exam.patient_id} />
      {overviewName?.id && <OldValuesWrapper examId={exam.id} activeTab={activeTab} />}
      {exam.is_telemed &&
        <div className='position-fixed user-cam'>
          <UserCam />
        </div>}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { other, auth, exam: { exam }, previousExam, setting: { orgConfig } } = state;
  return {
    overviewName: previousExam.editing,
    exam: exam,
    activeTab: other.activeTab.exam,
    store: exam.store_attributes,
    examViewModern: orgConfig.org_config?.exam_settings.view_mode == 'modern',
    currentUser: auth.user,
  }
}

export default connect(mapStateToProps)(ExamTabsContent);