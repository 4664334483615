import React, {Component} from 'react';
import {connect} from 'react-redux';
import {patientReviewOfSystemsLoad, patientReviewOfSystemLoad, patientReviewOfSystemCreate, patientReviewOfSystemUpdate, patientReviewOfSystemDelete, patientReviewOfSystemEdit, patientReviewOfSystemEditCancel, patientReviewOfSystemUpdateAllToNo} from '../../../redux/actions/patient/patientReviewOfSystemActions';
import {reviewOfSystemsLoad} from '../../../itrust_common/redux/actions/settingActions/reviewOfSystemActions';
import {filterChange, filterReset} from '../../../itrust_common/redux/actions/filterActions';
import { humanize, findByKey, filterByKey } from '../../../itrust_common/components/HelperFunctions';
import Alert from '../../../itrust_common/components/Alert';
class ReviewOfSystem extends Component {
  constructor(props){
    super(props);
    this.props.dispatch(filterReset());
    this.handlePatientReviewOfSystemsLoad({exam_id: props.examId, rpp: 500})
    this.props.dispatch(reviewOfSystemsLoad({rpp: 500, sort: 'category', direction: 'asc'}))
  }

  handlePatientReviewOfSystemsLoad(filters){
    this.props.dispatch(patientReviewOfSystemsLoad(this.props.patientId, filters));
    // this.props.dispatch(filterChange({filters: filters}));
  }

  handlePatientReviewOfSystemEdit(id){
    if (id !== 'New') this.props.dispatch(patientReviewOfSystemLoad(id));
    this.props.dispatch(patientReviewOfSystemEdit(id));
  }

  handlePatientReviewOfSystemEditCancel(){
    this.props.dispatch(patientReviewOfSystemEditCancel());
  }

  handlePatientReviewOfSystemDelete(id){
    Alert.deleteConfirm("Are you sure? You want to delete this record.",
    (response)=>{
      if(response)
        this.props.dispatch(patientReviewOfSystemDelete(id))
    })
  }

  handlePatientReviewOfSystemSubmit(data) {
    const id = data.id || '';
    if (id === '') {
      this.props.dispatch(patientReviewOfSystemCreate(this.props.patientId, {exam_examination_id: this.props.examId, ...data}))
    } else {
      this.props.dispatch(patientReviewOfSystemUpdate(this.props.patientId, {exam_examination_id: this.props.examId, ...data}))
    }
  }

  handlePatientReviewOfSystemUpdateAllToNo(category){
    if(category){
      this.props.dispatch(patientReviewOfSystemUpdateAllToNo(this.props.patientId, {exam_id: this.props.examId, rpp: 9999, category: category}));
    }
    else
      this.props.dispatch(patientReviewOfSystemUpdateAllToNo(this.props.patientId, {exam_id: this.props.examId, rpp: 9999}));
  }

  componentWillUnmount() {
    this.props.dispatch(filterReset());
  }

  render() {
    const {settingReviewOfSystems, patientReviewOfSystemsMeta, examEditing} = this.props
    return(
        <div>
          <div className="pt-6 w-100">
            { !examEditing && !examEditing.id && patientReviewOfSystemsMeta.has_pending_exam && 
              <div className="d-flex align-items-center pb-5">
                <i className="la la-warning text-danger mr-2"></i>
                <h6>This patient has an active unfinished exam. Syncing to patient profile changes must be made on the exam, and then finalized.</h6>
              </div>
            }
          <div className={!examEditing && !examEditing.id && patientReviewOfSystemsMeta.has_pending_exam ? "row bg-white mx-0 rounded shadow-sm pb-7 disabled" : "row bg-white mx-0 rounded shadow-sm pb-7"}>
              <div className="col-12 border-bottom border-bottom-2 border-light py-5 d-flex align-items-center justify-content-between ">
                <h6>Review Of Systems</h6>
                <button className="btn btn-outline-primary" onClick={this.handlePatientReviewOfSystemUpdateAllToNo.bind(this, null)}>No to all</button>
              </div>
            {Object.keys(settingReviewOfSystems).map(category =>
              filterByKey(settingReviewOfSystems?.[category], 'is_visible', true).length ? <div className="col-xl-4 col-6 px-7 pt-6">
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{humanize(category)}</h6>
                  <button className="btn btn-outline-primary" onClick={this.handlePatientReviewOfSystemUpdateAllToNo.bind(this, category)}>No to all</button>
                </div>
                <div className="row mx-0 mt-5">
                  {settingReviewOfSystems[category].map((question, index) =>
                    this.renderReviewOfSystem(question, index)
                  )}
                </div>
              </div >
                : null)}
            </div>
          </div>
        </div>
    );
  }

  renderReviewOfSystem(settingReviewOfSystem, index){
    const {patientReviewOfSystems} = this.props
    const patientReviewOfSystem = findByKey(patientReviewOfSystems, 'settings_review_of_system_id', parseInt(settingReviewOfSystem.id))
    var initialValues = {settings_review_of_system_id: settingReviewOfSystem.id}
    if(patientReviewOfSystem && patientReviewOfSystem.id){
      initialValues = {...initialValues, id: patientReviewOfSystem.id, is_active: patientReviewOfSystem.is_active}
    }
    return(
      settingReviewOfSystem.is_visible &&
        <div key={'reviewOfSystem-' + settingReviewOfSystem.id} className={`col-12 px-0 ${!settingReviewOfSystem.is_visible && 'opacity-5'}`}>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="mb-0">{settingReviewOfSystem.name}</p>
            <div className="d-flex flex-row justify-content-between radio-btn align-items-center">
              <div className={`radio-sm mr-3`} onClick={this.handlePatientReviewOfSystemSubmit.bind(this, {...initialValues, is_active: false})}>
                <div className="custom-control custom-radio">
                  <input name={`is_active-${settingReviewOfSystem.id}`} id={`no-${settingReviewOfSystem.id}`} className="custom-control-input" type="radio" checked={initialValues.is_active===false}/>
                  <label className="custom-control-label pt-1" htmlFor={`no-${settingReviewOfSystem.id}`}>No</label>
                </div>
              </div>
              <div className={`radio-sm mr-3`} onClick={this.handlePatientReviewOfSystemSubmit.bind(this, {...initialValues, is_active: true})}>
                <div className="custom-control custom-radio">
                  <input name={`is_active-${settingReviewOfSystem.id}`} id={`yes-${settingReviewOfSystem.id}`} className="custom-control-input" type="radio" checked={initialValues.is_active===true}/>
                  <label className="custom-control-label pt-1" htmlFor={`yes-${settingReviewOfSystem.id}`}>Yes</label>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}



const mapStateToProps = state => {
  const {patientReviewOfSystem, setting: {reviewOfSystem}, filter, exam } = state;
  return {
    saving: patientReviewOfSystem.saving,
    patientReviewOfSystems: patientReviewOfSystem.patient_review_of_systems,
    patientReviewOfSystemsMeta: patientReviewOfSystem.meta,
    settingReviewOfSystems: reviewOfSystem.review_of_systems,
    patientReviewOfSystemEditing: patientReviewOfSystem.editing,
    examEditing: exam.editing,
    filters: filter.filters,
  }
}

export default connect(mapStateToProps)(ReviewOfSystem);
