import React, { Component } from 'react';
import { Field, FieldArray, change } from 'redux-form';
import LensForm from './LensForm'

import { Checkbox, Input } from '../../../../form/'
import DateFormatView from '../../../../others/DateFormatView';

const clearContactLens = {
  is_marked_complete: true,
  od_contact_lens_id: null,
  os_contact_lens_id: null,
  od_draw: { lines: [] },
  os_draw: { lines: [] },
  is_trial: false,
  od_contact_lens_attributes: null,
  od_add: null,
  od_axis: null,
  od_curve: null,
  od_cylinder: null,
  od_diameter: null,
  od_lens_trial_order_quantity: null,
  od_lens_type: null,
  od_notes: null,
  od_over_refraction: null,
  od_sphere: null,
  os_contact_lens_attributes: null,
  os_add: null,
  os_axis: null,
  os_curve: null,
  os_cylinder: null,
  os_diameter: null,
  os_lens_trial_order_quantity: null,
  os_lens_type: null,
  os_notes: null,
  os_over_refraction: null,
  os_sphere: null,
  visual_acuity: {
    od_distance: null,
    od_near: null,
    os_distance: null,
    os_near: null,
    ou_distance: null,
    ou_near: null
  },
  notes: ""
}

class RenderLens extends Component {
  render() {
    const { template, isTemplate, fields, pprops } = this.props
    return (
      <span>
        {fields.map((member, index, members) => (
          <div className="card-body border-top border-top-2 border-light">
            <div className="d-flex flex-row justify-content-between align-items-center py-3 border-top-2 border-bottom-2 border-light">
              <div>
                {isTemplate ? "Current Contact Lens" : `${index !== 0 ? 'Contact Lens Trial #' : 'Current Contact Lens'}${index !== 0 ? index : ''}`}
              </div>
              <div>
                {
                  !isTemplate && index !== 0 &&
                  <button type="button" className="btn btn-danger" aria-label="Close" onClick={() => { pprops.dispatch(change('examRefractionContactLensForm', `${member}[_destroy]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                    Delete
                  </button>
                }
                {
                  !isTemplate && (index === fields.length - 1) &&
                  <button type="button" className="btn btn-primary ml-7 mr-5" onClick={() => { fields.push({ ...pprops.initialValues.current_lenses_attributes[fields.length - 1], id: null }); setTimeout(() => pprops.submit(), 500) }}>
                    Add New
                  </button>
                }

                {!isTemplate &&
                  <Field
                    name={`${member}[is_marked_complete]`}
                    component={Checkbox}
                    label={members.get(index).is_marked_complete ? <i className="la la-angle-up" /> : <i className="la la-angle-down" />}
                    className="d-none"
                    wrapperClasses="mr-6 d-inline-block checkbox-remove"
                    onChange={() => setTimeout(() => pprops.submit(), 500)}
                  />
                }
              </div>
            </div>

            {/* !isTemplate && pprops.initialValues.current_lenses_attributes[index]['is_finalized'] && <span className="badge badge-success"> Finalized </span> */}
            <div className={members.get(index).is_marked_complete || isTemplate ? '' : 'd-none'}>
              <LensForm
                {...this.props}
                currentContactLens={pprops.lensesAttributes[index] ? pprops.lensesAttributes[index] : { ...pprops.lensesAttributes[pprops.lensesAttributes.length - 1], id: null }}
                name={`current_lenses_attributes[${index}]`}
                baseName={`refraction_contact_lens_exam[contact_lens][fields][current_contact_lens][tests][0]`}
                fieldValues={template['refraction_contact_lens_exam']['contact_lens']['fields']['current_contact_lens']['tests'][0]}
              />
              <div className="d-flex flex-row justify-content-between align-items-center mt-7 pb-5">
                {!isTemplate &&

                  <div className="d-flex flex-row justify-content-between align-items-center mt-7">
                    <Field
                      name={`${member}[is_trial]`}
                      component={Checkbox}
                      label="Add to trial contact lens order"
                      wrapperClasses="mr-1 d-inline-block"
                      onChange={
                        (e) => {
                          setTimeout(() =>
                            pprops.dispatch(change('examRefractionContactLensForm', `${member}[marked_as_trial_on]`, e.target.value === "true" ? `${new Date()}` : '')), 500)
                        }
                      }
                    />

                    {/* { members.get(index) && members.get(index).is_finalized &&
                      <button type="button" className={`btn btn-link text-danger text-nowrap`} onClick={() => pprops.dispatch(tabChange({exam: 10}))}>Go To Final Rx <i className="la la-arrow-right la-sm"/></button>
                    } */}
                  </div>
                }

                {members.get(index) && members.get(index).trial_order_placed_at &&
                  <span className="badge badge-secondary">
                    Order Placed at
                    <DateFormatView dateTime={members.get(index).trial_order_placed_at} />
                  </span>
                }


                <div>
                  {!isTemplate &&
                    <button type="button" className="btn btn-outline-primary mr-7" onClick={() => { pprops.dispatch(change('examRefractionContactLensForm', member, { id: members.get(index).id, ...clearContactLens })); setTimeout(() => pprops.submit(), 500) }}>
                      Clear
                    </button>
                  }

                  {!isTemplate &&
                    <button type="button" className={`btn btn-primary mr-7`} onClick={() => { pprops.dispatch(change('examRefractionContactLensForm', `${member}[is_finalized]`, true)); setTimeout(() => pprops.submit(), 500) }}>
                      Finalize
                    </button>
                  }
                </div>
              </div>

              {members.get(index) && members.get(index).is_trial &&
                <div className="overview-table px-2 pt-5 border-top border-top-2 border-light">
                  <table className="table table-hover bg-light">
                    <thead className="text-dark border-bottom border-white border-bottom-2">
                      <tr>
                        <th scope="col">Trial Date Time</th>
                        <th scope="col">OD Lens Quantity</th>
                        <th scope="col">OS Lens Quantity</th>
                        <th scope="col" className="w-50">Lab Trial Order Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span className="text-nowrap">
                            {members.get(index).marked_as_trial_on && <DateFormatView dateTime={members.get(index).marked_as_trial_on} />}
                          </span>
                        </td>
                        <td>
                          <Field
                            name={`${member}[od_lens_trial_order_quantity]`}
                            component={Input}
                            type='number'
                            className="form-control"
                            wrapperClasses=""
                          />
                        </td>
                        <td>
                          <Field
                            name={`${member}[os_lens_trial_order_quantity]`}
                            component={Input}
                            type='number'
                            className="form-control"
                            wrapperClasses=""
                          />
                        </td>
                        <td>
                          <Field
                            name={`${member}[manufacturer_notes]`}
                            component={Input}
                            className="form-control"
                            wrapperClasses=""
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              }
            </div>
          </div>
        ))}
      </span>
    );
  }
}

const CurrentContactLens = props => {
  const { template, isTemplate, pprops } = props
  const name = isTemplate ? "refraction_contact_lens_exam[contact_lens][fields][current_contact_lens][tests]" : "current_lenses_attributes"
  return (
    <FieldArray name={name}
      props={{
        template: template,
        isTemplate: isTemplate,
        pprops: pprops,
      }}
      component={RenderLens} />
  );
}

export default CurrentContactLens;