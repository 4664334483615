import React from 'react'
import RPASubmitHistoryListing from './RPASubmitHistoryListing'
import { dateTimeFormat } from '../../../../../itrust_common/components/HelperFunctions'
import { rpaDetailView } from '../../../../../redux/actions/addOns/rpa/rpaAction'
import { connect, useDispatch } from 'react-redux'
import Tooltip from '../../../../../itrust_common/components/others/Tooltip'
import DateFormatView from '../../../../../itrust_common/components/others/DateFormatView'

function RPASubmitHistory(props) {
  const { isOrderView, insuranceType, order, patient, wrapperClasses } = props
  const dispatch = useDispatch()

  const expired_vision_insurance_logs = isOrderView ? order.patient_insurance_attributes?.expired_vision_insurance_logs : patient[insuranceType]?.expired_vision_insurance_logs

  return (
    <>
      {(expired_vision_insurance_logs && expired_vision_insurance_logs.length > 0) &&
        <div className={`bg-light border rounded d-flex justify-content-between align-items-start ${wrapperClasses}`}>
          <div className='py-4 px-5 d-flex flex-column'>
            <span className='d-flex'>
              <span className='font-weight-500'>iTRUST Visionary</span>
              <Tooltip tooltipContent={expired_vision_insurance_logs[0]?.error_message}>
                {expired_vision_insurance_logs[0]?.status === 'found' ?
                  <span className="badge rounded bg-success mx-3">Found</span> :
                  <span className="badge rounded bg-failed mx-3">Not Found
                    <i className="las la-info-circle ml-3"></i>
                  </span>
                }
              </Tooltip>
            </span>
            <p className='font-size-14 mb-0 mt-3'>
              <DateFormatView dateTime={expired_vision_insurance_logs[0]?.timestamp} />
            </p>
          </div>
          <div className='px-4 d-flex align-items-center'>
            {expired_vision_insurance_logs[0]?.status === 'found' ?
              <span className='text-primary font-size-24 mt-3' onClick={() => expired_vision_insurance_logs[0]?.status === 'found' && dispatch(rpaDetailView({ visible: true, file_url: expired_vision_insurance_logs[0]?.file, service: expired_vision_insurance_logs[0]?.service }))}>
                <i className="las la-eye"></i>
              </span> :
              <span className='text-danger font-size-24 mt-3'>
                <i className="las la-eye-slash"></i>
              </span>
            }
            <div className="dropdown bg-transparent ml-5 mt-3">
              <span className="dropdown-toggle font-size-24 text-primary" role="button" data-toggle="dropdown" aria-expanded="false">
                <i className="las la-history"></i>
              </span>
              <div className="dropdown-menu width-20 height-18">
                <h6 className='mx-4 border-bottom py-3 text-primary'>History</h6>
                <RPASubmitHistoryListing dispatch={dispatch} expired_vision_insurance_logs={expired_vision_insurance_logs} wrapperClasses="table-hover" />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const { order: { order }, patient: { patient } } = state
  return {
    order,
    patient,
  }
}

export default connect(mapStateToProps)(RPASubmitHistory);