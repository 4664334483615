import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { isDirty, submit } from 'redux-form';
import { tabChange } from '../../../itrust_common/redux/actions/otherActions';

function AutoSaveExamFormLogic(props) {
  const { activeTab, isDirtyForms, handleScrollToExamModules, showExternalInternalForm, examSaving } = props
  const dispatch = useDispatch()

  useEffect(() => {
    if (activeTab) {
      // Re-Rendered ExternalInternalForm UI rendering issue fixes
      !showExternalInternalForm && setTimeout(() => dispatch(tabChange({ showExternalInternalForm: true })), 1000);
      if (activeTab === 2) {
        handleScrollToExamModules()
      }
      // activeTab will be changed on flow:
      // Classic View: onTab click
      // Modern View: on Scroll, onTab click
      activeTab && onExamTabChange()
    }
  }, [activeTab]);

  const onExamTabChange = async () => {
    const FORM_CONFIG = [
      { name: 'examHpiForm', dirty: isDirtyForms.examHpiFormDirty },
      { name: 'examPreliminaryBinocularForm', dirty: isDirtyForms.examPreliminaryBinocularFormDirty },
      { name: 'examRefractionContactLensForm', dirty: isDirtyForms.examRefractionContactLensFormDirty },
      { name: 'examExternalInternalForm', dirty: isDirtyForms.examExternalInternalFormDirty },
      { name: 'examAdditionalTestForm', dirty: isDirtyForms.examAdditionalTestFormDirty, saving: examSaving },
      { name: 'examFinalizePrescriptionForm', dirty: isDirtyForms.examFinalizePrescriptionFormDirty },
    ];
    // if form value changed then save it
    for (const form of FORM_CONFIG) {
      if (form.dirty && !form.saving) {
        await dispatch(submit(form.name));
      }
    }
  }

  return (
    <></>
  )
}

const mapStateToProps = (state) => {
  const { other, exam } = state;
  return {
    activeTab: other.activeTab.exam,
    examSaving: exam.saving,
    showExternalInternalForm: other.activeTab.showExternalInternalForm,
    isDirtyForms: {
      examHpiFormDirty: isDirty('examHpiForm')(state),
      examPreliminaryBinocularFormDirty: isDirty('examPreliminaryBinocularForm')(state),
      examRefractionContactLensFormDirty: isDirty('examRefractionContactLensForm')(state),
      examExternalInternalFormDirty: isDirty('examExternalInternalForm')(state),
      examAdditionalTestFormDirty: isDirty('examAdditionalTestForm')(state),
      examFinalizePrescriptionFormDirty: isDirty('examFinalizePrescriptionForm')(state),
    }
  }
}

export default connect(mapStateToProps)(AutoSaveExamFormLogic);