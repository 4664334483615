import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery'

import { Preliminary, Binocular, Correction, PupillaryDistance, GlassView, Keratometry, CurrentGlass, ContactLens, External, Internal, Nct, Gat, VisualField, AdditionalTests, Procedure, ImpressionPlan, ContactLensPrescription, EyeGlassPrescription } from '../../../../components/authorised/exam/overview/index'
import { dateFormat, examFieldModelName, examImportFieldName, humanize, toggleId } from '../../../../itrust_common/components/HelperFunctions'

import { previousExamLoad } from '../../../../itrust_common/redux/actions/exam/previousExamActions'
import { examUpdate } from '../../../../redux/actions/exam/examActions';
import Alert from '../../../../itrust_common/components/Alert';
import ImportOldValues from './ImportOldValues';

class OldValuesWrapper extends Component {
  constructor(props) {
    super(props)
    this.handlePreviousExamLoad(this.props.examId)
    this.state = {
      activeExam: this.props.examId,
      selectedIds: []
    }
  }

  handlePreviousExamLoad(id) {
    this.props.dispatch(previousExamLoad(id, { include: '*' }))
    this.setState({ activeExam: id })
  }


  handleIdsSelect(ids, id) {
    var ids = toggleId(ids, id)
    this.setState({ selectedIds: ids })
  }

  import() {
    const { overviewName } = this.props;
    if (this.state.selectedIds.length === 0) {
      Alert.warning("No record selected!, Please select some values and than import it.")
    } else {
      let importDetails = {}
      if (overviewName.id == 'procedure' || overviewName.id == 'impression') {
        importDetails = { [`import_${overviewName.id}_ids`]: this.state.selectedIds }
      }
      else {
        importDetails = {
          import_exam_data_hash: {
            model_name: examFieldModelName(overviewName.id),
            record_id: this.state.selectedIds,
            import_field: examImportFieldName(overviewName.id),
          },
        }
      }
      new Promise((resolve, reject) => {
        this.props.dispatch(examUpdate({ id: this.props.examId, ...importDetails }, { include: '*' }))
          .then((response) => {
            this.setState({ selectedIds: [] })
            $(`#${overviewName.id}`).modal('toggle')
          })
      }
      )
    }
  }

  getModalSize(name) {
    if (name === "external_exam" || name === "internal_exam")
      return "modal-xl"
    else return "modal-lg"
  }

  render() {
    const { overviewName, allExams, previousExam, examId } = this.props
    return (
      <div className="inner-modal">
        <div className={`modal fade  ${(overviewName.id == "keratometry" || overviewName.id == "current_contact_lens") && "previous-value"}`} data-backdrop="static" id={overviewName.id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className={`modal-dialog ${this.getModalSize(overviewName.id)}`} role="document">
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <h5 className="modal-title" id="exampleModalLabel">Previous Test Values</h5>
                <div>
                  {(overviewName.id == 'contact_lens_prescription' || overviewName.id == 'eye_glass_prescription') ? '' :
                    <>
                      {(overviewName.id == 'procedure' || overviewName.id == 'impression' || overviewName.id === 'current_glasses' || overviewName.id === 'current_contact_lens') ?
                        <button type="button" className="btn btn-outline-primary" onClick={() => this.import()}>
                          Import Selected
                        </button> :
                        <ImportOldValues examId={examId} previousExam={previousExam} activeExam={this.state.activeExam} overviewName={overviewName} />}
                    </>
                  }
                  <button type="button" className="btn btn-outline-primary ml-4" onClick={() => $(`#${overviewName.id}`).modal('hide')}>
                    Close
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-between">
                  <h6>{humanize(overviewName.id)}</h6>
                  <div className="d-flex align-items-center">
                    <h6 className="mr-3 d-inline-block"> Accessing </h6>
                    <select className="custom-select" value={previousExam.id} onChange={(e) => this.handlePreviousExamLoad(e.target.value)}>
                      {allExams.map(exam =>
                        <option value={exam.id} key={exam.id}>{dateFormat(exam.exam_date)}</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 py-5">
                    {(overviewName.id && previousExam?.id) && this.renderComponent(overviewName.id)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderComponent(name) {
    const { previousExam, previousExam: { template: { preliminary_binocular_testing, refraction_contact_lens_exam, external_internal_exam, procedures_and_impressions, finalize_prescription, additional_tests } } } = this.props
    switch (name) {
      case "preliminary":
        return <Preliminary fieldsValues={previousExam.priliminaries} template={preliminary_binocular_testing.priliminaries} />
      case "binocular":
        return <Binocular fieldsValues={previousExam.binocular} template={preliminary_binocular_testing.binocular} />
      case "visual_acuity_without_correction":
        return <Correction fieldsValues={previousExam.visual_acuity_without_correction} template={refraction_contact_lens_exam.visual_acuity.fields.without_correction} />
      case "visual_acuity_with_correction":
        return <Correction fieldsValues={previousExam.visual_acuity_with_correction} template={refraction_contact_lens_exam.visual_acuity.fields.with_correction} />
      case "current_glasses":
        return <CurrentGlass overviewName="current_glasses" fieldsValues={previousExam.current_glasses_attributes} template={refraction_contact_lens_exam.visual_acuity.fields.current_glass} handleIdsSelect={this.handleIdsSelect.bind(this)} selectedIds={this.state.selectedIds} />
      case "pupillary_distance":
        return <PupillaryDistance fieldsValues={previousExam.pupillary_distance} template={refraction_contact_lens_exam.refraction.fields.pupillary_distance} />
      case "objective":
        return <GlassView fieldsValues={previousExam.objective_attributes} template={refraction_contact_lens_exam.refraction.fields.objective} />
      case "subjective":
        return <GlassView fieldsValues={previousExam.subjective_attributes} template={refraction_contact_lens_exam.refraction.fields.subjective} />
      case "cycloplegic":
        return <GlassView fieldsValues={previousExam.cycloplegic_attributes} template={refraction_contact_lens_exam.refraction.fields.cycloplegic} />
      case "binocular_balance":
        return <GlassView fieldsValues={previousExam.binocular_balance_attributes} template={refraction_contact_lens_exam.refraction.fields.binocular_balance} />
      case "keratometry":
        return <Keratometry name="keratometry_attributes" fieldsValues={previousExam.keratometry_attributes} template={refraction_contact_lens_exam.contact_lens.fields.keratometry} />
      case "current_contact_lens":
        return <ContactLens overviewName="current_contact_lens" fieldsValues={previousExam.current_lenses_attributes} template={refraction_contact_lens_exam.contact_lens.fields.current_contact_lens.tests[0]} handleIdsSelect={this.handleIdsSelect.bind(this)} selectedIds={this.state.selectedIds} />
      case "intraoffice_notes":
        return <p>{previousExam.intraoffice_notes?.note}</p>

      case "external_exam":
        return <External fieldsValues={previousExam.external_attributes} template={external_internal_exam.external_exam} />
      case "gat":
        return <Gat fieldsValues={previousExam.gat} template={external_internal_exam.gat} />
      case "nct":
        return <Nct fieldsValues={previousExam.nct} template={external_internal_exam.nct} />
      case "visual_field":
        return <VisualField fieldsValues={previousExam.visual_field} template={external_internal_exam.visual_field} />
      case "internal_exam":
        return <Internal fieldsValues={previousExam.internal_attributes} template={external_internal_exam.internal_exam} />

      case "visual_summary_field":
        return <AdditionalTests overviewName="visual_summary_field" fieldsValues={previousExam} template={additional_tests} />
      case "amsler_grid":
        return <AdditionalTests overviewName="amsler_grid" fieldsValues={previousExam} template={additional_tests} />
      case "external_imaging":
        return <AdditionalTests overviewName="external_imaging" fieldsValues={previousExam} template={additional_tests} />
      case "punctal_plug":
        return <AdditionalTests overviewName="punctal_plug" fieldsValues={previousExam} template={additional_tests} />
      case "epilation":
        return <AdditionalTests overviewName="epilation" fieldsValues={previousExam} template={additional_tests} />
      case "foreign_body_removal":
        return <AdditionalTests overviewName="foreign_body_removal" fieldsValues={previousExam} template={additional_tests} />
      case "skin_tag_removal":
        return <AdditionalTests overviewName="skin_tag_removal" fieldsValues={previousExam} template={additional_tests} />
      case "amniotic_membrane":
        return <AdditionalTests overviewName="amniotic_membrane" fieldsValues={previousExam} template={additional_tests} />
      case "pachymetry":
        return <AdditionalTests overviewName="pachymetry" fieldsValues={previousExam} template={additional_tests} />
      case "inflamma_dry":
        return <AdditionalTests overviewName="inflamma_dry" fieldsValues={previousExam} template={additional_tests} />
      case "tear_lab":
        return <AdditionalTests overviewName="tear_lab" fieldsValues={previousExam} template={additional_tests} />
      case "internal_imaging":
        return <AdditionalTests overviewName="internal_imaging" fieldsValues={previousExam} template={additional_tests} />
      case "oct":
        return <AdditionalTests overviewName="oct" fieldsValues={previousExam} template={additional_tests} />
      case "topography":
        return <AdditionalTests overviewName="topography" fieldsValues={previousExam} template={additional_tests} />
      case "digital_retina_wellness_screening":
        return <AdditionalTests overviewName="digital_retina_wellness_screening" fieldsValues={previousExam} template={additional_tests} />

      case "procedure":
        return <Procedure overviewName="procedure" handleIdsSelect={this.handleIdsSelect.bind(this)} selectedIds={this.state.selectedIds} fieldsValues={previousExam.procedures_attributes} template={procedures_and_impressions.procedure} />
      case "impression":
        return <ImpressionPlan overviewName="impression" handleIdsSelect={this.handleIdsSelect.bind(this)} selectedIds={this.state.selectedIds} fieldsValues={previousExam.impressions_attributes} template={procedures_and_impressions.impression} />

      case "contact_lens_prescription":
        return <ContactLensPrescription fieldsValues={previousExam.finalized_lenses_attributes} template={finalize_prescription.contact_lens} />
      case "eye_glass_prescription":
        return <EyeGlassPrescription fieldsValues={previousExam.finalized_glasses_attributes} template={finalize_prescription.eye_glass} />
      default:
    }
  }
}

const mapStateToProps = state => {
  const { exam, previousExam } = state
  return {
    allExams: exam.exam.all_exams,
    overviewName: previousExam.editing,
    previousExam: previousExam.previousExam
  }
}
export default connect(mapStateToProps)(OldValuesWrapper);