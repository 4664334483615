import React, { Component } from 'react';
import { connect } from 'react-redux';

import { examDelete } from '../../../redux/actions/exam/examActions';
import Alert from '../../../itrust_common/components/Alert';
import { dateFormat, humanize } from '../../../itrust_common/components/HelperFunctions';
import DateFormatView from '../../../itrust_common/components/others/DateFormatView';

class DeleteExamButton extends Component {
  
  handleExamDelete(id){
    const { exam, onDeleteHook } = this.props;

    Alert.deleteConfirm(<div>This action cannot be undone. The exam of <strong>{humanize(exam.patient_attributes.name)}</strong> created on <strong><DateFormatView date={exam.exam_date} /></strong> by <strong>{humanize(exam.provider_name)}</strong> would be permanently deleted and won't be recovered back. Are you sure to delete it?</div>, 
      (res)=> { 
        if(res){
          this.props.dispatch(examDelete(id))
          onDeleteHook && onDeleteHook()
        } 
      }
    )
  }

  render() {
    const { exam, children } = this.props;
    return (
      <span className="pointer" onClick={this.handleExamDelete.bind(this, exam.id)}>
        {children}
      </span>
    );
  }
}

export default connect()(DeleteExamButton);
