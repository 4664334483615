import React, { useEffect, useState } from 'react'
import PurposeForUseItrustForm from './PurposeForUseItrustForm'
import onBoardingLadyImg from '../../../../assets/static/images/onboarding-lady.png';
import iTrustLogoImg from '../../../../itrust_common/assets/static/images/itrust-logo.png';
import { useDispatch } from 'react-redux';
import { organisationUpdate } from '../../../../redux/actions/organisationActions';
import GuideStep2 from './GuideStep2';
import GuideStep3 from './GuideStep3';
import _ from 'lodash';
import { findKeyValuePairFromValue } from '../../../../itrust_common/components/HelperFunctions';
import Toastr from '../../../../itrust_common/components/Toastr';

export default function GuideModal(props) {
  const { handleTourStart, purposeForUseItrust, userRole, isPurposeFormDirty } = props
  const [activeGuideTab, setActiveGuideTab] = useState(["super_admin"].includes(userRole) ? 1 : 2)
  const dispatch = useDispatch()

  useEffect(() => {
    if (["super_admin"].includes(userRole)) {
      const itrustUsePurposeSelected = findKeyValuePairFromValue(purposeForUseItrust, true) || purposeForUseItrust?.other // Purpose Modal is filled
      itrustUsePurposeSelected && activeGuideTab == 1 && setActiveGuideTab(2)
    }
  }, [])

  const handlePurposeForUseItrustSubmit = async (data) => {
    let anyFieldIsTrue = findKeyValuePairFromValue(data.onboarding_details.utilization_purpose, true)
    if (anyFieldIsTrue || data.onboarding_details.utilization_purpose?.other) {
      // handled old org > new super_admin case
      data = data?.onboarding_details.user_progress?.first_login_at ? data : { ...data, onboarding_details: { ...data.onboarding_details, user_progress: { first_login_at: 'old_org' } } }
      isPurposeFormDirty && await dispatch(organisationUpdate({ ...data }))
      setActiveGuideTab(2)
    }
    else {
      return Toastr.handleShow(false, "Please select what you are looking for")
    }
  }

  return (
    <div className="modal fade" id="onBoardingModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="onBoardingModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered mx-auto modal-lg">
        <div className="modal-content">
          <div className="p-5 bg-light text-center">
            {activeGuideTab === 2 ?
              <img className='width-11' src={iTrustLogoImg} /> :
              <img className='width-5' src={onBoardingLadyImg} />
            }
          </div>
          <div className="modal-body">
            <div>
              {/* null denotes old organisation */}
              {(activeGuideTab === 1 && ["super_admin"].includes(userRole)) &&
                <PurposeForUseItrustForm onSubmit={handlePurposeForUseItrustSubmit} dispatch={dispatch} />
              }
              {activeGuideTab === 2 && <GuideStep2 />}
              {activeGuideTab === 3 && <GuideStep3 />}
              {activeGuideTab === 2 &&
                <div className='mt-8 d-flex justify-content-end align-items-center'>
                  {["super_admin"].includes(userRole) &&
                    <span className='mr-5 font-size-30 text-primary' onClick={() => setActiveGuideTab(1)}>
                      <i className="las la-angle-left"></i>
                    </span>
                  }
                  <button className='btn btn-primary' onClick={() => setActiveGuideTab(3)}>Next</button>
                </div>
              }
              {activeGuideTab === 3 &&
                <div className='mt-8 d-flex justify-content-end align-items-center'>
                  <span className='mr-5 font-size-30 text-primary' onClick={() => setActiveGuideTab(2)}>
                    <i className="las la-angle-left"></i>
                  </span>
                  <button className='btn btn-primary' onClick={() => handleTourStart(true)}>Finish</button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
