import React, {Component} from 'react';
import {findDOMNode} from 'react-dom'
import { Field, change } from 'redux-form';
import {FormTemplateField} from '../../../others'
import {Input, Textarea, Checkbox, DatePicker, ConditionalDropdown} from '../../../form/'
import { rangeBuilder } from '../../../HelperFunctions'
import { signedNumber, twoDecimalPlaces } from '../../../form/FormValidations';
import { connect } from 'react-redux';

class GlassForm extends Component{
  copyTo(source, destination){
    const {name, pprops} = this.props
    const sphereNode = findDOMNode(this.refs[`${name}[${source}_sphere]`]).lastChild.value
    const cylinderNode = findDOMNode(this.refs[`${name}[${source}_cylinder]`]).lastChild.value
    const axisNode = findDOMNode(this.refs[`${name}[${source}_axis]`]).lastChild.value
    const addNode = findDOMNode(this.refs[`${name}[${source}_add]`]).lastChild.value
    const prismNode = findDOMNode(this.refs[`${name}[${source}_prism]`]).lastChild.value
    pprops.dispatch(change('examFinalizePrescriptionForm', `${name}[${destination}_sphere]`, sphereNode))
    pprops.dispatch(change('examFinalizePrescriptionForm', `$ {name}[${destination}_cylinder]`, cylinderNode))
    pprops.dispatch(change('examFinalizePrescriptionForm', `${name}[${destination}_axis]`, axisNode))
    pprops.dispatch(change('examFinalizePrescriptionForm', `${name}[${destination}_add]`, addNode))
    pprops.dispatch(change('examFinalizePrescriptionForm', `${name}[${destination}_prism]`, prismNode))
  }
  render(){
    const { name, baseName, fieldValues, isTemplate, examViewModern, pprops: {recommendations}} = this.props

    return (
      <span>
        <div className="row">
          <div className="col-xl-7 col-md-12">
            <div className="row">
              <Field 
                ref={`${name}[od_sphere]`}
                name={`${name}[od_sphere]`}
                label={this.renderOdLabel()} 
                component={ConditionalDropdown} supportAutoComplete={true} 
                data={!examViewModern ? [{ key: 'Bal' }, ...rangeBuilder(20, -25.25, -0.25, '+', 'pl')] : [...rangeBuilder(25, -25.25, -0.25, '+', 'pl')]}
                additionalValues={{ last: [{ key: ' ' }, { key: 'Bal' }], start: [] }}
                textField="key" 
                valueField="key" 
                validate={!examViewModern ? [] : [signedNumber, twoDecimalPlaces]}
                wrapperClasses="col label-icons" />
              <Field
                ref={`${name}[od_cylinder]`}
                name={`${name}[od_cylinder]`}
                component={ConditionalDropdown} supportAutoComplete={true} 
                additionalValues={{ start: [{ key: ' ' }], last: [] }}
                data={!examViewModern ? [...rangeBuilder(6.0, -10.25, -0.25, '+', ' ', 2, 'DS')] : [...rangeBuilder(6, -6.25, -0.25, '+', 'DS', 2)]}
                textField="key" valueField="key" wrapperClasses="col pt-6"
                validate={!examViewModern ? [] : [signedNumber, twoDecimalPlaces]}
              />
              <Field ref={`${name}[od_axis]`} name={`${name}[od_axis]`} component={ConditionalDropdown} supportAutoComplete={true}  
                data={!examViewModern ? [{ key: ' ' }, ...rangeBuilder(1, 181, 1, '', '', 0)] : [{ key: ' ' }, ...rangeBuilder(1, 181, 1, '', '', 0)]}
              textField="key" valueField="key" wrapperClasses="col pt-6" leftPosition={'-18.8rem'} />
              <Field ref={`${name}[od_add]`} name={`${name}[od_add]`} component={ConditionalDropdown} supportAutoComplete={true}  
                validate={!examViewModern ? [] : [signedNumber, twoDecimalPlaces]}
                data={!examViewModern ? [{ key: ' ' }, ...rangeBuilder(0.25, 6.25, 0.25, "+", "0.00")] : [{ key: ' ' }, { key: 'ADD' }, ...rangeBuilder(0.25, 6.25, 0.25, '+', '0.00')]}
              textField="key" valueField="key" wrapperClasses=" col pt-6" />
              <Field ref={`${name}[od_prism]`} name={`${name}[od_prism]`} component={Input} placeholder="Prism" className="form-control mt-6" wrapperClasses="col form-group" />
              <FormTemplateField
                isTemplate={isTemplate}
                name={`${name}[issue_date]`}
                baseName={`${baseName}[fields][issue_date]`}
                fieldValues={fieldValues['fields']['issue_date']}
                component={DatePicker} 
                placeholder="dd-mm-yy" 
                wrapperClasses="col-3 form-group"
               />
            </div>
            <div className="row">
              <Field 
                ref={`${name}[os_sphere]`}
                name={`${name}[os_sphere]`} 
                label={this.renderOsLabel()} 
                component={ConditionalDropdown} supportAutoComplete={true}  
                additionalValues={{ last: [{ key: ' ' }, { key: 'Bal' }], start: [] }}
                data={!examViewModern ? [{ key: 'Bal' }, ...rangeBuilder(20, -25.25, -0.25, '+', 'pl')] : [...rangeBuilder(25, -25.25, -0.25, '+', 'pl')]}
                textField="key" 
                valueField="key" 
                validate={!examViewModern ? [] : [signedNumber, twoDecimalPlaces]}
                wrapperClasses="col label-icons"/>
              <Field
                ref={`${name}[os_cylinder]`}
                name={`${name}[os_cylinder]`}
                component={ConditionalDropdown} supportAutoComplete={true} 
                additionalValues={{ start: [{ key: ' ' }], last: [] }}
                data={!examViewModern ? [...rangeBuilder(6.0, -10.25, -0.25, '+', ' ', 2, 'DS')] : [...rangeBuilder(6, -6.25, -0.25, '+', 'DS', 2)]}
                validate={!examViewModern ? [] : [signedNumber, twoDecimalPlaces]}
                textField="key" valueField="key" wrapperClasses="col pt-6"
              />
              <Field ref={`${name}[os_axis]`} name={`${name}[os_axis]`} component={ConditionalDropdown} supportAutoComplete={true}  
                data={!examViewModern ? [{ key: ' ' }, ...rangeBuilder(1, 181, 1, '', '', 0)] : [{ key: ' ' }, ...rangeBuilder(1, 181, 1, '', '', 0)]}
              textField="key" valueField="key" wrapperClasses="col pt-6" leftPosition={'-18.8rem'} />
              <Field ref={`${name}[os_add]`} name={`${name}[os_add]`} component={ConditionalDropdown} supportAutoComplete={true} 
                data={!examViewModern ? [{ key: ' ' }, ...rangeBuilder(0.25, 6.25, 0.25, "+", "0.00")] : [{ key: ' ' }, { key: 'ADD' }, ...rangeBuilder(0.25, 6.25, 0.25, '+', '0.00')]}
                validate={!examViewModern ? [] : [signedNumber, twoDecimalPlaces]}
                textField="key" valueField="key" wrapperClasses="col pt-6" />
              <Field ref={`${name}[os_prism]`} name={`${name}[os_prism]`} component={Input} placeholder="Prism" className="form-control mt-6" wrapperClasses="col form-group" />
              <FormTemplateField
                isTemplate={isTemplate}
                name={`${name}[expiry_date]`}
                baseName={`${baseName}[fields][expiry_date]`}
                fieldValues={fieldValues['fields']['expiry_date']}
                component={DatePicker} 
                placeholder="dd-mm-yy" 
                wrapperClasses="col-3 form-group label-icons"
               />
            </div>
          </div>
          <div className="col-xl-5 col-md-12">
            <div className="row">
              <FormTemplateField
                isTemplate={isTemplate}
                name={`${name}[notes]`}
                baseName={`${baseName}[fields][notes]`}
                fieldValues={fieldValues['fields']['notes']}
                component={Textarea}
                rows={4}
                className="form-control" 
                wrapperClasses="col-5 form-group"
               />
              <div className="col-7">
                <span>Recommendations</span>
                <div className="row recommendation-layout mt-2">
                  {recommendations.map((recommendation)=> 
                    <Field name={`${name}[recommendations]['${recommendation.name}']`} component={Checkbox} label={recommendation.name} wrapperClasses="col-6"/>
                  )}
                </div>
                <small>Scroll to add more</small>
              </div>
            </div>
          </div>
        </div>
      </span>
    )
  }

  
  renderOdLabel(){
    return(
      <span>OD<small className="float-right btn-link" onClick={()=> !this.props.isTemplate && this.copyTo('od', 'os')}><i className="la la-copy"/>OS</small></span>
      )
    }

  renderOsLabel(){
    return(
      <span>OS<small className="float-right btn-link" onClick={()=> !this.props.isTemplate && this.copyTo('os', 'od')}><i className="la la-copy"/>OD</small></span>
    )
  }
}

const mapStateToProps = (state) => {
  const { setting: { orgConfig } } = state
  return {
    examViewModern: orgConfig.org_config?.exam_settings.view_mode == 'modern'
  }
}

export default connect(mapStateToProps)(GlassForm);