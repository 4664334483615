import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../itrust_common/components/AsyncComponent';

const ItrustPayDashboardWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/itrustPay/itrustPayDashboard/ItrustPayDashboardWrapper')
)

const PaymentLogsWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/itrustPay/itrustPayPaymentLogs/PaymentLogsWrapper')
)

const SettingWrapper = asyncComponent(() =>
  import('../../../containers/authorised/addOns/itrustPay/settings/SettingWrapper')
)

const ItrustPayRoutes = (
  <Switch>
    <Route exact path='/add-ons/itrust-pay/dashboard' component={ItrustPayDashboardWrapper} />
    <Route exact path='/add-ons/itrust-pay/transaction-logs' component={PaymentLogsWrapper} />
    <Route exact path='/add-ons/itrust-pay/settings' component={SettingWrapper} />
  </Switch>
)

export default ItrustPayRoutes;