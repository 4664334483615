import React, { Component } from 'react';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import { connect } from 'react-redux';

import {Input, FileUpload} from '../../../../itrust_common/components/form';
import Alert from '../../../../itrust_common/components/Alert';
let RenderDoc = props => {
  const {fields, submit, dispatch} = props

  const handleRemoveExamDoc = (member) => {
    Alert.deleteConfirm("Are you sure? You want to delete this record.",
      (res) => {
        if (res) {
          dispatch(change("examAttachedDoc", `${member}[_destroy]`, true));
          setTimeout(() => submit(), 500)
        }
      })
  }

  return (
    <div className="row bg-white">
      {fields.map((member, index, members) => (
        <div key={index} className="col-xl-3 col-md-6 mt-7" style={{maxHeight: "20rem"}}>
          { index === 0 ?
            <Field name={`${member}[document_attributes]`} onChangeHook={()=> {setTimeout(()=> submit(), 1000)}} component={FileUpload} imageClasses="img-fluid" labelClasses="d-block" wrapperClasses="col-12 form-group" />
            :
            <div className="w-100">
              <embed src={members.get(index).document_attributes.file.url} className="pt-5 img-fluid" style={{ height: '12rem', objectFit: "fit" }}></embed>
              <div className="col-12 my-5 px-0">
                <div className="d-flex">
                  <Field name={`${member}[description]`} placeholder="Enter File description  ..." component={Input} className="form-control" wrapperClasses="form-group mb-0 w-100" onBlur={() => { setTimeout(() => submit(), 100) }} />
                  <div className="dropright d-inline-block float-right badge badge-pill badge-primary px-1 pt-3 ml-5">
                    <button type="button" className="btn p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="la la-ellipsis-v"/>
                    </button>
                    <div className="dropdown-menu border-0 shadow-sm">
                      <ul className="list-unstyled px-5 mb-0">
                        <li className="py-3 pointer"><a className="text-dark d-block" target="_blank" href={members.get(index).document_attributes.file.url}>View</a></li>
                        <li className="py-3 pointer" onClick={() => handleRemoveExamDoc(member)}>Remove</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      ))}
    </div>
  );
}

class AttachedDoc extends Component {

  componentWillUnmount(){
    const { saving } = this.props;
    if(this.props.dirty){
      !saving && this.props.handleSubmit();
    }
  }

  render(){
    const { handleSubmit, template, isTemplate, submit, dispatch } = this.props;
    return (
      <form onSubmit={handleSubmit} className="px-7 py-5">
        <FieldArray name="patient_documents_attributes"
          props = {{
            template: template,
            isTemplate: isTemplate,
            submit: submit,
            dispatch: dispatch
          }} 
          component={RenderDoc} />
      </form>
    )
  }
}

AttachedDoc = reduxForm({
  form: 'examAttachedDoc',
  enableReinitialize: true,
})(AttachedDoc)

AttachedDoc = connect(state => {
  const { exam } = state
  return {
    saving: exam.saving,
    initialValues: {
      id: exam.exam.id,
      patient_documents_attributes: [{ patient_id: exam.exam.patient_id }, ...exam.exam.patient_documents_attributes]
    }
  }
})(AttachedDoc)

export default AttachedDoc;