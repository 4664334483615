import { BandwidthUA } from '@bandwidth/bw-webrtc-sdk';
import Alert from '../../../../../itrust_common/components/Alert';
import bandwidthRingtoneMp3 from '../../../../../assets/static/images/bandwidth_ringtone.mp3'
import { bandwidthCallRecordingAndStatusUpdate, callingInformationUpdate, makeOutgoingCall } from '../../../../../redux/actions/addOns/voip/voipActions';
import { startRecording, stopRecording } from '../CallRecorder';

export const bandwidthDeviceInitialize = async (token, dispatch, callLogId, isCallRecordingActive, isIncoming) => {
  if (token) {
    const serverConfig = {
      domain: 'gw.webrtc-app.bandwidth.com',
      addresses: ['wss://gw.webrtc-app.bandwidth.com:10081'],
      iceServers: [
        'stun.l.google.com:19302',
        'stun1.l.google.com:19302',
        'stun2.l.google.com:19302',
      ],
    };
    const bandwidthDevice = new BandwidthUA();
    bandwidthDevice.setServerConfig(
      serverConfig.addresses,
      serverConfig.domain,
      serverConfig.iceServers
    );
    bandwidthDevice.setOAuthToken(token);
    handleBandwidthDeviceListeners(bandwidthDevice, dispatch, callLogId, isCallRecordingActive, isIncoming)
    return bandwidthDevice
  }
  else {
    Alert.warning('Token is not present.')
  }
}

export const handleBandwidthDeviceListeners = (device, dispatch, callLogId, isCallRecordingActive, isIncoming) => {
  let isCallConfirmed = false
  device.setListeners({
    loginStateChanged: function (isLogin, cause) {
      switch ('cause' + cause) {
        case 'connected':
          console.log("Device connected.")
          break;
        case 'disconnected':
          console.log("Device disconnected.")
          break;
        case 'login failed':
          console.log("Device login failed.")
          break;
        case 'login':
          console.log("Device login.")
          break;
        case 'logout':
          console.log("Device logout.")
          break;
      }
    },

    outgoingCallProgress: function (call, response) {
      !isIncoming && dispatch(callingInformationUpdate({ callStatus: 'connecting' }))
      return console.log('Connecting...')
    },

    callTerminated: async function (call, message, cause) {
      dispatch(callingInformationUpdate({ callStatus: 'call_ended', isCallActive: false }))
      if (!isIncoming) {
        let file = isCallRecordingActive ? await stopRecording() : null
        let callDuration = isCallConfirmed ? (Date.now() - isCallConfirmed) / 1000 : null
        let status = isCallConfirmed ? 'completed' : message ? 'no-answer' : 'canceled'
        let data = { call_log_id: callLogId, file: file, duration: callDuration, status: status }
        dispatch(bandwidthCallRecordingAndStatusUpdate({ ...data }))
      }
      console.log("Call ended.")
    },
    callShowStreams: function (call, localStream, remoteStream) {
      !isIncoming && dispatch(callingInformationUpdate({ callStatus: 'ringing' }))
      let remoteVideo = document.getElementById('remote-video-container');
      remoteVideo.srcObject = remoteStream;
      console.log('Ringing...')
    },
    callConfirmed: function (call, message, cause) {
      dispatch(callingInformationUpdate({ callStatus: 'call_started' }))
      isCallConfirmed = Date.now()
      call.muteAudio(false);
      isCallRecordingActive && startRecording()
      console.log('Call confirmed...')
    },
    incomingCall: function (call, invite) {
      console.log("Incoming Call")
    },

    callHoldStateChanged: function (call, isHold, isRemote) {
      if (isHold) {
        console.log("Call is in hold state.")
      }
    }
  });
}

const bandwidthDeviceSetup = async (device, sourceNumber) => {
  await device.checkAvailableDevices();
  await device.setAccount(`${sourceNumber}`, 'In-App Calling Sample');
  await device.init();
}

export const bandwidthOutGoingCallConnect = async (res, phoneNumber, isIncoming, dispatch) => {
  // sourceNumber as per BE requirements
  let sourceNumber = isIncoming ? res.res.to : res.res.from
  await bandwidthDeviceSetup(res.device, sourceNumber)
  setTimeout(async () => {
    if (res.device.isInitialized()) {
      const call = await res.device.call(`${phoneNumber}`)
      dispatch(callingInformationUpdate({ call: call }))
    }
  }, 3000);
}

export const bandwidthClientSetup = async (data, dispatch, isCallRecordingActive, isIncoming) => {
  // in case of incoming call we get token in receiveIncomingCall api
  let res = isIncoming ? data : await dispatch(makeOutgoingCall(data))
  res = res.action.payload.data["add_ons/voip/call_log"]
  const device = await bandwidthDeviceInitialize(res.token, dispatch, res.id, isCallRecordingActive, isIncoming)
  return { device, res }
}

export const bandwidthIncomingCallInitialize = async (data, dispatch) => {
  // on accept of  incoming call, outgoing call made with from & to same num.
  bandwidthRingtoneRinging(false)
  let res = await bandwidthClientSetup(data, dispatch, false, true)
  await bandwidthOutGoingCallConnect(res, res.res.to, true, dispatch)
}

export const bandwidthRingtoneRinging = (isPlay) => {
  // handle bandwidth ringtone play/pause
  let bandwidthRingtone = document.getElementById('bandwidth-ringtone');
  bandwidthRingtone.src = isPlay ? bandwidthRingtoneMp3 : '';
}