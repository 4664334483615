import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import asyncComponent from '../../itrust_common/components/AsyncComponent';

const Highlights = asyncComponent(() =>
  import('../../containers/authorised/organisation/Highlights')
);
const Integration = asyncComponent(() =>
  import('../../containers/authorised/organisation/Integration')
);
const Stores = asyncComponent(() =>
  import('../../containers/authorised/organisation/store/Stores.js')
);
const Staff = asyncComponent(() =>
  import('../../containers/authorised/organisation/staff/Staffs.js')
)
const StorePrevileges = asyncComponent(() =>
  import('../../containers/authorised/organisation/staff/staffStorePermission/StorePrevileges')
)
const PerDaySchedule = asyncComponent(() =>
  import('../../containers/authorised/organisation/staff/staffSchedule/PerDaySchedule')
)
const AnonymousSchedule = asyncComponent(() =>
  import('../../containers/authorised/organisation/staff/staffSchedule/AnonymousSchedule')
)
const Schedule = asyncComponent(() =>
  import('../../containers/authorised/organisation/store/storeSchedule/PerDaySchedule')
)

const ExamProcedurePricing = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/procedurePricing/ProcedurePricing')
)

const DefaultBillingProvider = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/providerDetails/DefaultBillingProvider')
)

const DefaultProvider = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/defaultProvider/DefaultProvider')
)

const ServiceProviderLocation = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/providerDetails/ServiceProviderLocation')
)

const PrescriptionExpiration = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/prescriptionExpiration/PrescriptionExpiration')
)

const MessageTracking = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/messageTracking/MessageTracking')
)

const StoreContact = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/storeContact/StoreContact')
)

const DeviceIntegration = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/deviceIntegration/DeviceIntegration')
)

const StoreEyeglassLensType = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/orderSettings/eyeglassLens/StoreEyeglassLensType')
)
const StoreEyeglassAddons = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/orderSettings/eyeglassLens/StoreEyeglassAddons')
)
const StoreEyeglassFrame = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/orderSettings/eyeglassLens/StoreEyeglassFrame')
)
const StoreEyeglassLensMaterial = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/orderSettings/eyeglassLens/StoreEyeglassLensMaterial')
)
const StoreMiscItems = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/orderSettings/eyeglassLens/StoreMiscItems')
)
const StoreContactLens = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/orderSettings/contactLens/StoreContactLens')
)
const StoreCleaningSolution = asyncComponent(() =>
  import('../../containers/authorised/storeSettings/orderSettings/contactLens/StoreCleaningSolution')
)

const OrganisationRoutes = (
  <Switch>
    <Route exact path="/organisation/highlights" component={Highlights} />
    <Route exact path="/organisation/stores" component={Stores} />
    <Route exact path="/organisation/staff" component={Staff} />
    <Route exact path="/organisation/integration" component={Integration} />
    <Redirect exact from="/organisation" to="/organisation/highlights" />

    <Route exact path="/organisation/stores/:id/staff" component={Staff} />
    <Route exact path="/organisation/stores/:id/schedule" component={Schedule} />

    {/* store office settings */}
    <Route exact path="/organisation/stores/:id/settings/store/exam-procedure-pricing" component={ExamProcedurePricing} />
    <Route exact path="/organisation/stores/:id/settings/store/default-billing-provider" component={DefaultBillingProvider} />
    <Route exact path="/organisation/stores/:id/settings/store/default-provider" component={DefaultProvider} />
    <Route exact path="/organisation/stores/:id/settings/store/service-provider-location" component={ServiceProviderLocation} />
    <Route exact path="/organisation/stores/:id/settings/store/prescription-expiration" component={PrescriptionExpiration} />
    <Route exact path="/organisation/stores/:id/settings/store/device-integration" component={DeviceIntegration} />
    <Route exact path="/organisation/stores/:id/settings/message-tracking-number" component={MessageTracking} />
    <Route exact path="/organisation/stores/:id/settings/contact" component={StoreContact} />
    <Redirect exact from="/organisation/stores/:id/settings/store" to="/organisation/stores/:id/settings/store/exam-procedure-pricing" />
    <Route exact path="/organisation/staffs/:id/store-privileges" component={StorePrevileges} />
    <Route exact path="/organisation/staffs/:id/schedule" component={PerDaySchedule} />
    <Route exact path="/organisation/staffs/:id/anonymous-schedule" component={AnonymousSchedule} />
    {/* order-inventory routes */}
    <Route exact path="/organisation/stores/:id/settings/order-inventory/eyeglass" component={StoreEyeglassLensType} />
    <Route exact path="/organisation/stores/:id/settings/order-inventory/eyeglass/lens-material" component={StoreEyeglassLensMaterial} />
    <Route exact path="/organisation/stores/:id/settings/order-inventory/eyeglass/frame" component={StoreEyeglassFrame} />
    <Route exact path="/organisation/stores/:id/settings/order-inventory/eyeglass/add-ons" component={StoreEyeglassAddons} />
    <Route exact path="/organisation/stores/:id/settings/order-inventory/misc" component={StoreMiscItems} />
    {/* contact lens */}
    <Route exact path="/organisation/stores/:id/settings/order-inventory/contact-lens" component={StoreContactLens} />
    <Route exact path="/organisation/stores/:id/settings/order-inventory/contact-lens/cleaning-solution" component={StoreCleaningSolution} />
  </Switch>
);


export default OrganisationRoutes;
