import React, { useEffect } from 'react'
import { ImportButton } from '../../../itrust_common/components/others'
import EditOrderButton from '../buttons/EditOrderButton'
import Alert from '../../../itrust_common/components/Alert'
import DeleteExamButton from '../buttons/DeleteExamButton'
import { connect, useDispatch } from 'react-redux'
import { medicalRxEdit } from '../../../redux/actions/medicalRxActions'
import * as $ from 'jquery';
import { examUpdate } from '../../../redux/actions/exam/examActions'
import { handleStopExamRecording } from './CommonFunctions'
import { isDirty, submit } from 'redux-form'
import { tabChange } from '../../../itrust_common/redux/actions/otherActions'
import { dateFormat } from '../../../itrust_common/components/HelperFunctions'
import { Link } from 'react-router-dom'

function ExamHeader(props) {
  const { exam, onSubmit, handleExamFinalize, handleExamPdfPrint, handleExamEditCancel, accordionToggle, setAccordionToggle, activeTab, examViewModern, isDirtyForms, currentUser } = props
  const { order_attributes, patient_attributes, status } = exam
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => {
      if ((currentUser.role === 'non_admin' && exam.status === "finalized" && currentUser.id !== exam.staff_attributes.id)) {
        examOverviewModal()
      }
    }, 1000);
  }, [exam.id, exam.status])

  const handleMedicalRxEdit = () => {
    dispatch(medicalRxEdit('New'));
    $('#medicalRxModal').modal('show')
  }

  const handleExamFromClose = () => {
    exam.is_telemed ? handleStopSaveRecording(exam).then(() => $('#editExamForm-modal').modal('hide')) :
      $('#editExamForm-modal').modal('hide')
    handleSaveExam()
    dispatch(tabChange({ showExternalInternalForm: false, showOverviewModal: false }))
  }

  const handleStopSaveRecording = async (exam) => {
    const res = await handleStopExamRecording()
    res && dispatch(examUpdate({ id: exam.id, telemed_visits_attributes: [res] }, { include: '*' }))
  }

  const examOverviewModal = () => {
    dispatch(tabChange({ showOverviewModal: true }))
    $('#examOverviewModal').modal('show')
  }

  const FORM_CONFIG = [
    { name: 'examHpiForm', dirty: isDirtyForms.examHpiFormDirty },
    { name: 'examPreliminaryBinocularForm', dirty: isDirtyForms.examPreliminaryBinocularFormDirty },
    { name: 'examRefractionContactLensForm', dirty: isDirtyForms.examRefractionContactLensFormDirty },
    { name: 'examExternalInternalForm', dirty: isDirtyForms.examExternalInternalFormDirty },
    { name: 'examAdditionalTestForm', dirty: isDirtyForms.examAdditionalTestFormDirty },
    { name: 'examFinalizePrescriptionForm', dirty: isDirtyForms.examFinalizePrescriptionFormDirty },
  ];

  const handleSaveExam = async () => {
    if (examViewModern) {
      for (const form of FORM_CONFIG) {
        if (form.dirty) {
          await dispatch(submit(form.name));
        }
      }
    }
    else {
      switch (activeTab) {
        case 2: dispatch(submit('examHpiForm')); break
        case 6: dispatch(submit('examPreliminaryBinocularForm')); break
        case 7: dispatch(submit('examRefractionContactLensForm')); break
        case 8: dispatch(submit('examExternalInternalForm')); break
        case 9: dispatch(submit('examAdditionalTestForm')); break
        case 11: dispatch(submit('examFinalizePrescriptionForm')); break
        default:
      }
    }
  }

  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <div className="d-flex align-items-start">
        <div>
          <h5 className="mr-2">Patient Exam Form</h5>
          <span className="ml-1"><strong>ID:</strong> {exam.id}</span>
        </div>
        <a className="collapsed border border-primary rounded-circle ml-2" onClick={() => setAccordionToggle(!accordionToggle)} role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          <i className="la la-sm la-angle-down text-primary px-2 pt-2" />
        </a>
        {!accordionToggle && <div className='px-5 d-flex'>
          <div>
            <div>
              <span className="text-nowrap">Name:</span>
              <Link to={`/patients/${exam.patient_attributes.id}/overview`} className="btn-link" onClick={() => $('#editExamForm-modal').modal('hide')}> {exam.patient_attributes.name}</Link>
            </div>
            <div>
              <span className="text-nowrap">DOB & Age:</span>
              <span className="text-muted pl-3 text-nowrap">{dateFormat(exam.patient_attributes.date_of_birth)} | {exam.patient_attributes.age}</span>
            </div>
          </div>
          <div className='ml-5'>
            <div>
              <span className="text-nowrap">Exam Date:</span>
              <span className="text-muted pl-3 text-nowrap">{dateFormat(exam.exam_date)}</span>
            </div>
            <div>
              <span className="text-nowrap">Recall Date:</span>
              <span className="text-muted pl-3 text-nowrap">{dateFormat(exam.patient_attributes.recall_reminder_date)}</span>
            </div>
          </div>
        </div>}
      </div>
      <div className="d-flex">
        {(examViewModern && (!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id))) && <button onClick={examOverviewModal} type="button" className="btn btn-outline-primary mx-3">Overview</button>}
        {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) && <button className="btn btn-outline-primary mx-3" onClick={handleSaveExam}>Save Exam</button>}
        {exam.is_telemed &&
          <button
            type="button"
            className="btn btn-danger danger-pulse mx-3">
            Recording
          </button>
        }
        {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) && <label className='mb-0'>
          <ImportButton label="Import from Device" className="btn btn-outline-primary mx-3" {...props} isDeviceData={true} params={{ id: exam.id }} />
        </label>}
        {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) && <>
          {order_attributes ?
            <EditOrderButton isTelemedExam={exam.is_telemed} storeId={order_attributes && order_attributes.store_id} orderId={order_attributes && order_attributes.id}>
              <button className={`btn btn-outline-primary mx-3`} aria-disabled="true">Open Order</button>
            </EditOrderButton> :
            <button
              type="button"
              className="btn btn-outline-primary mx-3"
              onClick={() => Alert.confirm(<div>You're creating order for <strong>{patient_attributes.name}</strong>.</div>, (res) => res && onSubmit({ id: exam.id, create_order_from_exam: true }))}>
              Create Order
            </button>
          }
        </>}
        {status === "finalized" ?
          <button type="button" className="btn btn-success mx-3">Finalized</button> :
          <button type="button" className="btn btn-outline-primary mx-3" onClick={handleExamFinalize}>Finalize Exam</button>
        }
        <div className="dropdown d-inline-block mx-3 bg-light">
          <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            More Actions
            <i className="ml-3 la la-angle-down la-sm" />
          </button>
          <div className="dropdown-menu border-0 shadow rounded my-3" aria-labelledby="dropdownMenuButton">
            {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) && <a className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3" data-toggle="modal" href="#referralModal">
              <i className="la la-sm mr-3 la-edit" />
              Referral
            </a>}
            {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) && <a onClick={handleMedicalRxEdit} className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3 pointer" >
              <i className="la la-sm mr-3 la-prescription" />
              e-SCRIBE
            </a>}
            <a onClick={handleExamPdfPrint} className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3 pointer">
              <i className="la la-sm mr-3 la-print" />
              Print
            </a>
            {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) && <DeleteExamButton exam={exam} onDeleteHook={handleExamEditCancel}>
              <a className="dropdown-item d-flex align-items-center text-danger py-3"><i className="la la-trash la-sm mr-3" />Delete</a>
            </DeleteExamButton>}
          </div>
        </div>
        <button className="btn btn-outline-primary mx-3" onClick={() => handleExamFromClose(exam)}>Close</button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { other, auth, exam: { exam }, organisation, medicalRx, previousExam, setting: { orgConfig } } = state;
  return {
    overviewName: previousExam.editing,
    exam: exam,
    activeTab: other.activeTab.exam,
    organisation: organisation.organisation,
    store: exam.store_attributes,
    medicalRxes: medicalRx.medical_rxes,
    currentUser: auth.user,
    examViewModern: orgConfig.org_config?.exam_settings.view_mode == 'modern',
    isDirtyForms: {
      examHpiFormDirty: isDirty('examHpiForm')(state),
      examPreliminaryBinocularFormDirty: isDirty('examPreliminaryBinocularForm')(state),
      examRefractionContactLensFormDirty: isDirty('examRefractionContactLensForm')(state),
      examExternalInternalFormDirty: isDirty('examExternalInternalForm')(state),
      examAdditionalTestFormDirty: isDirty('examAdditionalTestForm')(state),
      examFinalizePrescriptionFormDirty: isDirty('examFinalizePrescriptionForm')(state),
    }
  }
}

export default connect(mapStateToProps)(ExamHeader)