import React, { Component } from 'react';
import { connect } from 'react-redux';

import { orderDelete } from '../../../redux/actions/order/orderActions';
import Alert from '../../../itrust_common/components/Alert';
import { dateFormat, humanize } from '../../../itrust_common/components/HelperFunctions';
import { patientLoad } from '../../../redux/actions/patient/patientActions';
import DateFormatView from '../../../itrust_common/components/others/DateFormatView';

class DeleteOrderButton extends Component {
  
  handleOrderDelete(id){
    const { order, onDeleteHook } = this.props;

    Alert.deleteConfirm(<div>This action cannot be undone. The order <strong>{order.id}</strong> of <strong>{humanize(order.patient_attributes.name)}</strong> created on <strong><DateFormatView date={order.order_date} /></strong> would be permanently deleted and won't be recovered back. Are you sure to delete it?</div>, 
      (res) => {
        if (res) {
          this.props.dispatch(orderDelete(id))
            .then(() => this.props.dispatch(patientLoad(order.patient_attributes.id, { include: '*' })))
          onDeleteHook && onDeleteHook()
        } 
      }
    )
  }

  render() {
    const { order, children } = this.props;
    return (
      <span className="pointer" onClick={this.handleOrderDelete.bind(this, order.id)}>
        {children}
      </span>
    );
  }
}

export default connect()(DeleteOrderButton);
