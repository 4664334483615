import React from 'react';
import DisplayField from '../../../../itrust_common/components/others/DisplayField'
import DateFormatView from '../../../../itrust_common/components/others/DateFormatView';
import { dateFormat } from '../../../../itrust_common/components/HelperFunctions';

let PatientInformation = props => {
  const { referral, isPdfView } = props;

  return (
    <div className="row">
      <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3">Referral Information</h6>
        </div>
      </div>
      <DisplayField
        isVisible={true}
        label={"Referral Date:"}
        value={isPdfView ? dateFormat(referral.referral_date, 'D MMM YYYY') : <DateFormatView date={referral.referral_date} />}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={true}
        label={"Request to See:"}
        value={referral.request_to_see}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={true}
        label={"Referral Reason:"}
        value={referral.referral_reason}
        className="col-4 py-4"
      />
      <DisplayField
        isVisible={true}
        label={"Message:"}
        value={(referral.body_message)}
        className="col-4 py-4"
      />
    </div>
  )
}


export default PatientInformation;