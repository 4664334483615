import React from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../../../../../itrust_common/components/HelperFunctions';
import { layoutChange } from '../../../../../../itrust_common/redux/actions/otherActions';
import DateFormatView from '../../../../../../itrust_common/components/others/DateFormatView';

function PatientNotes(props) {
  const { contact, intraoffice_notes, layout } = props
  const dispatch = useDispatch()

  const handlePatientRedirect = () => {
    if (layout !== "default") {
      dispatch(layoutChange('default'))
    }
  }

  return (
    <>
      <div className='d-flex justify-content-end font-size-14 m-1'>
        <span className='text-primary pointer border-1 border-primary border-bottom'>

          <Link to={`/patients/${contact.id}/intraoffice-note`} className="text-primary" onClick={handlePatientRedirect}>View All</Link>
        </span>
      </div>
      <div className='calling-inner-height-2 overflow-auto px-3'>
        {intraoffice_notes?.length > 0 ?
          <>
            {intraoffice_notes.map((note) => <div className='d-flex align-items-baseline mb-4 font-size-14' key={`intraOfficeNote-${note.id}`}>
              <div className={`rounded-circle mx-2 circle-10 ${getImportanceClass(note)}`}></div>
              <div>
                <span>{note.note}</span><br />
                <span className='text-secondary'>
                  <DateFormatView date={note.created_at} />
                </span>
              </div>
            </div>)}
          </> :
          <p className='text-center py-8'>No Notes Found</p>
        }
      </div>
    </>
  )
}

const getImportanceClass = (note) => {
  switch (note.importance) {
    case 'high':
      return 'bg-danger'
    case 'medium':
      return 'bg-warning'
    default:
      return 'bg-success'
  }
}

const mapStateToProps = state => {
  const { intraofficeNote, filter, other } = state

  return {
    filters: filter.filters,
    intraoffice_notes: intraofficeNote.intraoffice_notes,
    layout: other.layout,
  }
}

export default connect(mapStateToProps)(PatientNotes);