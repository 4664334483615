import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'

let MedicalHistoryWrapper = props => {
  const { records, name, isFamily } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="border-bottom">
          <h6 className="pb-3 pt-6">{name}</h6>
        </div>
      </div>
      {records && records.length !== 0 ?
        <div className="col-12 pt-4">
          <div className="overview-table mb-3">
            <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
              <thead className="text-dark border border-muted">
                <tr className="text-center">
                  <th scope="col">
                    <DisplayField
                      isVisible={true}
                      label={"Disease"}
                    />
                  </th>
                  {/* {isDatesVisible &&
                    <th scope="col">
                      <DisplayField
                        isVisible={true}
                        label={"Status"}
                      />
                    </th>} */}
                  {isFamily &&
                    <th scope="col">
                      <DisplayField
                        isVisible={true}
                        label={"Relation"}
                      />
                    </th>}
                  {/* {isDatesVisible &&
                    [<th scope="col">
                      <DisplayField
                        isVisible={isDatesVisible}
                        label={"Begin Date"}
                      />
                    </th>,
                    <th scope="col">
                      <DisplayField
                        isVisible={isDatesVisible}
                        label={"End Date"}
                      />
                    </th>]
                  } */}
                  <th scope="col">
                    <DisplayField
                      isVisible={true}
                      label={"History Of Illness"}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {records.map(record =>
                  <tr className="text-center">
                    <td>
                      <DisplayField
                        isVisible={true}
                        value={record.name}
                      />
                    </td>
                    {/* {isDatesVisible &&
                      <td>
                        <DisplayField
                          isVisible={true}
                          value={record.is_illness_active ? "Active" : "Inactive"}
                        />
                      </td>
                    } */}
                    {isFamily &&
                      <th scope="col">
                        <DisplayField
                          isVisible={true}
                          value={record.relation}
                        />
                      </th>}
                    <td>
                      <DisplayField
                        isVisible={true}
                        value={record.details}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        :
        <DisplayField
          isVisible={true}
          value={"Negative"}
          className="col-12 py-4"
        />
      }
    </div>
  )
}


export default MedicalHistoryWrapper;