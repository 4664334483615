import React from 'react';
import { connect } from 'react-redux';
import { dateFormat, checkIsVisible } from "../../../itrust_common/components/HelperFunctions"

//Tabs import
import StoreInformation from './overview/StoreInformation'
import PatientInformation from './overview/PatientInformation'
import Hpi from './overview/Hpi'
import MedicalHistory from './overview/medicalHistory/index'
import PreliminaryBinocular from './overview/preliminaryBinocular/index'
import RefractionContactLens from './overview/refractionContactLens/index'
import ExternalInternalTests from './overview/externalInternalTests/index'
import ProcedureImpressionPlan from './overview/procedureImpressionPlan';
import FinalizePrescription from './overview/finalizePrescription/index';
import AdditionalTests from './overview/additionalTests/index';
import OverviewFooter from './overview/OverviewFooter';
let Overview = props => {
  const { showExternalInternalForm,
    isPdfView, store, medicalHistories, exam,
    exam: { patient_attributes, template: { preliminary_binocular_testing, refraction_contact_lens_exam, external_internal_exam, procedures_and_impressions, finalize_prescription, additional_tests, hpi, medical_history } } } = props;

    return (
    <div id="OverviewTab" className={`${isPdfView && "pdfView table-bg-white px-6"} bg-white pdf-fax-font-change`}>
      <div className="border-bottom p-6">
        <StoreInformation store={store} />
      </div>
      <div className={`text-right py-4 px-6`}>
        <span className="font-weight-bold"> Exam Date: </span><span>{dateFormat(exam.exam_date)}</span> <br/>
          {exam.patient_attributes.recall_reminder_date && <><span className="font-weight-bold"> Recall Date: </span><span>{dateFormat(exam.patient_attributes.recall_reminder_date)}</span></>}
      </div>
      <div className="px-6 pt-6 pb-4">
        <PatientInformation patient={patient_attributes} isPdfView={isPdfView} />
      </div>

      {checkIsVisible(hpi.visible) && <div className="px-6 pt-6 pb-4">
        <Hpi fieldsValues={exam} template={hpi} />
      </div>}

      {checkIsVisible(medical_history.visible) && <div className="px-6 pt-6 pb-4">
        <MedicalHistory fieldsValues={medicalHistories} neurologicalStatus={exam.neurological_psychological_status}/>
      </div>}

      {checkIsVisible(preliminary_binocular_testing.visible) && <div className="px-6 py-4">
        <PreliminaryBinocular
          fieldsValues={exam}
          template={preliminary_binocular_testing}
        />
      </div>}

      {checkIsVisible(refraction_contact_lens_exam.visible) && <div className="px-6 py-4">
        <RefractionContactLens
          fieldsValues={exam}
          template={refraction_contact_lens_exam}
          isPdfView={isPdfView}
        />
      </div>}

      {checkIsVisible(external_internal_exam.visible) && <div className="px-6 py-4">
          {(showExternalInternalForm || isPdfView) && <ExternalInternalTests
          fieldsValues={exam}
          template={external_internal_exam}
          isPdfView={isPdfView}
          store={store}
        />}
      </div>}

      {checkIsVisible(additional_tests.visible) && <div className="px-6 py-4">
        <AdditionalTests
          fieldsValues={exam}
          template={additional_tests}
        />
      </div>}

      <div className="px-6 py-4">
        <ProcedureImpressionPlan
          fieldsValues={exam}
          template={procedures_and_impressions}
        />
      </div>

      <div className="px-6 py-4">
        <FinalizePrescription
          fieldsValues={exam}
          template={finalize_prescription}
        />
      </div>

      <div className="px-6 py-4">
        <OverviewFooter />
      </div>

    </div>
  )
}

const mapStateToProps = state => {
  const { patientMedicalHistory } = state
  return {
    medicalHistories: patientMedicalHistory.patient_medical_histories
  }
}

export default connect(mapStateToProps)(Overview);
