import React, { useEffect } from 'react'
import RpaClaimSubmitButton from '../../../../claimForm/submitClaim/RpaClaimSubmitButton'
import { connect, useDispatch } from 'react-redux'
import EyeMedStepForm from './EyeMedStepForm'
import { reduxForm } from 'redux-form'
import ScrollToTarget from '../../../../../../../../itrust_common/components/others/ScrollToTarget'
import { rpaClaimFormEdit, rpaClaimFormUpdate } from '../../../../../../../../redux/actions/addOns/rpa/rpaClaimFormAction'
import ClaimFormOverview from '../ClaimFormOverview'
import ClaimFormSync from '../../../../../../../../components/authorised/order/edit/ClaimFormSync'
import Alert from '../../../../../../../../itrust_common/components/Alert'
import { dateTimeFormat } from '../../../../../../../../itrust_common/components/HelperFunctions'
import DateFormatView from '../../../../../../../../itrust_common/components/others/DateFormatView'

function EyeMedClaimForm(props) {
  const { rpaConfig, order, handleSubmit, editing, rpaClaimForm, claimFormTemplate } = props
  const dispatch = useDispatch()
  let filters = {}

  useEffect(() => {
    if (rpaClaimForm.billing?.claim_data?.charges?.length) {
      dispatch(rpaClaimFormEdit({ formView: 'overview' }))
    }
    else {
      dispatch(rpaClaimFormEdit({ formView: 'form' }))
    }
  }, [rpaClaimForm.billing?.claim_data?.charges])

  const getActiveModuleArr = (res) => {
    return res.eligibility.data.map(item => item.active && item.service_type ? item.service_type : '')
  }

  const getActiveModule = (res) => {
    const activeModulesArr = getActiveModuleArr(res)
    if (activeModulesArr.includes('Exam')) {
      return 'Exam'
    }
    else if (activeModulesArr.includes("Contact Lens Fit and Follow-up")) {
      return "Contact Lens Fit and Follow-up"
    }
  }

  const getActiveTabModule = (res) => {
    const { activeTab, activeModule } = editing;
    res = res ?? rpaClaimForm
    const activeModulesArr = getActiveModuleArr(res)
    switch (activeTab) {
      case 'first':
        dispatch(rpaClaimFormEdit({ activeTab: 2, activeModule: getActiveModule(res) }));
        break;
      case 2:
        if (activeModule === 'Exam') {
          if (res.exams.exam?.is_cpt2_selected == 'no') {
            const newTab = activeModulesArr.includes("Contact Lens Fit and Follow-up") ? { activeTab: 2, activeModule: 'Contact Lens Fit and Follow-up' } : { activeTab: 'final', activeModule: '' };
            dispatch(rpaClaimFormEdit(newTab));
          }
          else {
            dispatch(rpaClaimFormEdit({ activeTab: 3, activeModule: 'Exam' }));
          }
        } else if (activeModule === 'Contact Lens Fit and Follow-up') {
          dispatch(rpaClaimFormEdit({ activeTab: 'final', activeModule: '' }));
        }
        break;
      case 3:
        if (activeModule === 'Exam') {
          const newTab = activeModulesArr.includes("Contact Lens Fit and Follow-up") ? { activeTab: 2, activeModule: 'Contact Lens Fit and Follow-up' } : { activeTab: 'final', activeModule: '' };
          dispatch(rpaClaimFormEdit(newTab));
        }
        break;
      case 'final':
        dispatch(rpaClaimFormEdit({ activeTab: '', activeModule: '' }));
        dispatch(rpaClaimFormEdit({ formView: 'overview' }))
        break;
      default:
        break;
    }
  };

  const computeData = (data) => {
    const filterActive = (items) => Array.isArray(items) ? items.filter(item => item.active) : items;
    const { exams } = data;
    if (exams) {
      //  filter each exam field is checked
      exams.exam && Object.keys(exams.exam).forEach(field => {
        if (exams.exam[field]) {
          exams.exam[field] = filterActive(exams.exam[field]);
        }
      });
      // filter each impression field is checked
      exams.impression && Object.keys(exams.impression).forEach(field => {
        if (exams.impression[field]) {
          exams.impression[field]['selected_value'] = filterActive(exams.impression[field]['selected_value']);
          exams.impression[field]['cpt_codes'] = filterActive(exams.impression[field]['cpt_codes']);
        }
      });
    }
    else {
      return { ...data, exams: { exam: {}, impression: {} }, contact_lens_fit_and_follow_up: {} }
    }
    return data;
  };

  const validateFormData = (data) => {
    const isAnyModuleSelected = data.eligibility?.data.find(item => item.active)
    // validate final step billing amount
    let chargesValidateArr = data.claim_data?.charges?.map(item => Number(item.charge))
    const hasNaN = chargesValidateArr ? (chargesValidateArr.find(item => Number.isNaN(item)) !== undefined) : false
    if (!isAnyModuleSelected) {
      Alert.warning('Please select a service to proceed.')
      return false
    }
    if (hasNaN) {
      Alert.warning('Invalid Amount')
      return false
    }
    return true
  }

  const getFilters = (data) => {
    const { activeTab, activeModule } = editing;
    const activeModulesArr = getActiveModuleArr(rpaClaimForm)
    switch (activeTab) {
      case 'first':
        return { claim_sections: ['eligibility'] }
      case 'final':
        return { claim_sections: ['charges'] }
      case 2:
        if (activeModule === 'Exam') {
          if (data.exams?.exam?.is_cpt2_selected === 'no' && !activeModulesArr.includes("Contact Lens Fit and Follow-up")) {
            return { claim_sections: ['exams', 'billing'] }
          }
          else {
            return { claim_sections: ['exams'] }
          }
        } else if (activeModule === 'Contact Lens Fit and Follow-up') {
          return { claim_sections: ['contact_lens_fit_and_follow_up', 'billing'] }
        }
        break;
      case 3:
        if (activeModule === 'Exam') {
          if (activeModulesArr.includes("Contact Lens Fit and Follow-up")) {
            return { claim_sections: ['impression'] }
          }
          else {
            return { claim_sections: ['impression', 'billing'] }
          }
        }
        break;
      default:
        return {}
    }
  }

  const handleClaimFormUpdate = async (data) => {
    data = computeData(data)
    const isFormValid = validateFormData(data)
    filters = getFilters(data)
    if (isFormValid) {
      let res = await dispatch(rpaClaimFormUpdate(data, filters))
      res = res.value.data.insurance_claim_form
      getActiveTabModule(res)
      filters = {}
    }
    return
  }

  const handleSyncSuccessfully = () => {
    dispatch(rpaClaimFormEdit({ activeTab: 'first', activeModule: '', formView: 'form' }));
  }

  return (
    <div className='mx-10 px-6'>
      {order.insurance_payer_changed && <h5 className='text-danger mb-5 text-center'>The insurance payer name is changed for the selected insurance.</h5>}
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          {rpaConfig?.visible && order.rpa_claim_exist && <RpaClaimSubmitButton order={order} />}
          {editing.formView !== 'overview' &&
            <button className='btn btn-outline-primary' onClick={() => dispatch(rpaClaimFormEdit({ formView: 'overview' }))}>Overview</button>
          }
          {order.claim_submission_at && <span className='text-danger ml-6'>Previous Claim Send on <DateFormatView dateTime={order.claim_submission_at} />
          </span>}
        </div>
        <div className='d-flex'>
          {/* <ClaimFormDownload /> */}
          <ClaimFormSync label="Reset" handleAfterSyncSuccessfully={handleSyncSuccessfully} />
        </div>
      </div>
      {editing.formView == 'overview' ?
        <ClaimFormOverview rpaClaimForm={rpaClaimForm} claimFormTemplate={claimFormTemplate} /> :
        <form onSubmit={handleSubmit(handleClaimFormUpdate)}>
          <EyeMedStepForm pprops={{ ...props }} />
        </form>
      }
    </div>
  )
}

EyeMedClaimForm = reduxForm({
  form: 'EyeMedClaimForm',
  enableReinitialize: true,
  shouldValidate: () => true,
  onSubmitFail: () => ScrollToTarget()
})(EyeMedClaimForm)

const mapStateToProps = (state) => {
  const { organisation: { organisation }, order: { order }, rpaClaimForm: { rpaClaimForm, saving, editing } } = state

  return {
    rpaConfig: organisation.add_ons_config?.type?.rpa,
    order,
    rpaClaimForm,
    editing,
    saving,
    initialValues: {
      ...rpaClaimForm,
    }
  }
}

export default connect(mapStateToProps)(EyeMedClaimForm)