import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'
import { dateFormat, buildHashString } from '../../../../../itrust_common/components/HelperFunctions';
import DateFormatView from '../../../../../itrust_common/components/others/DateFormatView';

let GlassView = props => {
  const { fieldsValues, template } = props;
  return (
    <div className="row">
      <div className="col-12">
        <div className="overview-table mb-3">
          <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col"></th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_sphere.visible}
                    label={template.fields.od_sphere.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_cylinder.visible}
                    label={template.fields.od_cylinder.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_axis.visible}
                    label={template.fields.od_axis.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_add.visible}
                    label={template.fields.od_add.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_prism.visible}
                    label={template.fields.od_prism.custom_label}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {["od", "os", "ou"].map(type =>
                <tr className="text-center">
                  <td>
                    <h6>{type.toUpperCase()}</h6>
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      // label={template.fields.distance_od.custom_label}
                      value={fieldsValues[`${type}_sphere`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      // label={template.fields.near_od.custom_label}
                      value={fieldsValues[`${type}_cylinder`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      // label={template.fields.near_od.custom_label}
                      value={fieldsValues[`${type}_axis`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      // label={template.fields.near_od.custom_label}
                      value={fieldsValues[`${type}_add`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      // label={template.fields.near_od.custom_label}
                      value={fieldsValues[`${type}_prism`]}
                    />
                  </td>
                </tr>
              )
              }
            </tbody>
          </table>
        </div>
      </div>
      <DisplayField
        isVisible={template.fields.issue_date.visible}
        label={`${template.fields.issue_date.custom_label}:`}
        value={<DateFormatView date={fieldsValues.issue_date} />}
        className="col-2 py-4"
      />
      <DisplayField
        isVisible={template.fields.expiry_date.visible}
        label={`${template.fields.expiry_date.custom_label}:`}
        value={<DateFormatView date={fieldsValues.expiry_date} />}
        className="col-2 py-4"
      />
      {fieldsValues.recommendations && Object.keys(fieldsValues.recommendations).length > 0 && 
      <div className="col-2 py-4">
        <DisplayField
          isVisible={template.fields.recommendations.visible}
          label={`${template.fields.recommendations.custom_label}:`}
          value={fieldsValues.recommendations && buildHashString(fieldsValues.recommendations)}
          className="d-inline-block"
        />
      </div> }
      { fieldsValues.notes && <DisplayField
        isVisible={template.fields.notes.visible}
        label={`${template.fields.notes.custom_label}:`}
        value={fieldsValues.notes}
        className="col-2 py-4"
      /> }
    </div>
  )
}


export default GlassView;