import React, { useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import { callPopUpEdit, voipEdit } from '../../../../../../redux/actions/addOns/voip/voipActions'
import { patientMessagesLoad } from '../../../../../../redux/actions/patient/patientMessageActions'

import { contactListsLoad, contactLoad } from '../../../../../../redux/actions/addOns/contactListsAction'

import { filterChange } from '../../../../../../itrust_common/redux/actions/filterActions'
import Search from '../../../../../../itrust_common/components/others/Search'
import { dateFormat, formatPhoneNumber } from '../../../../../../itrust_common/components/HelperFunctions'
import { Link } from 'react-router-dom'
import DateFormatView from '../../../../../../itrust_common/components/others/DateFormatView'

export default function PatientContact(props) {
  const { filters, saving } = props
  const dispatch = useDispatch()

  useEffect(() => {
    handleContactListsLoad({ ...filters, include: '*' })
  }, [])

  function handleContactListsLoad(filters) {
    dispatch(contactListsLoad(filters));
    dispatch(filterChange({ filters: filters }));
  }

  const handleCallMessagePatientLoad = async (patientId, editType) => {
    if (editType === 'patient_message') {
      let filter = { ...filters, sort: "send_at", direction: 'asc', rpp: 999999999, include: ['patient', 'store', 'staff', 'message_template'] }
      await dispatch(contactLoad(patientId, { include: '*' }))
      await dispatch(patientMessagesLoad({ ...filter, patient_id: patientId }))
      dispatch(callPopUpEdit(editType))
      dispatch(voipEdit({ prevTab: 'patients' }))
    }
    else {
      await dispatch(contactLoad(patientId, { include: '*' }))
      dispatch(callPopUpEdit(editType))
      dispatch(voipEdit({ prevTab: 'patients' }))
    }
  }

  const { contactLists, contactsCount } = props
  return (
    <div className='p-4'>
      <div className="search-bar w-100">
        <Search inputClasses="form-control" iconClasses="py-0" placeholder="Type to search..." onChangeHook={(query) => handleContactListsLoad({ ...filters, search: query, page: 1 })} />
      </div>
      <div className='calling-inner-height overflow-auto my-2'>
        {saving && <div className='d-flex align-items-center text-primary justify-content-center my-5'>
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <strong className='ml-3 font-size-18'>Loading...</strong>
        </div>}
        {contactLists?.length > 0 ?
          <>
            {contactLists?.map((contact_list) => <div className='d-flex flex-row justify-content-between m-3 font-size-14' key={contact_list.id}>
              <div className='d-flex flex-column'>
                <span>
                  <Link to={`/patients/${contact_list.id}/overview`} className="text-primary">{contact_list.name}</Link> {contact_list.cell_phone?.length > 6 ? `| ${formatPhoneNumber(contact_list.cell_phone)}` : ''}
                </span>
                <span className='text-secondary'>
                  DOB: <DateFormatView date={contact_list.date_of_birth} />
                  {contact_list.date_of_birth && <span className='text-secondary'> | {contact_list.age}</span>}
                </span>
              </div>
              <div className='font-size-18'>
                <button className="las la-phone border-0 bg-transparent text-primary pr-0" onClick={() => handleCallMessagePatientLoad(contact_list.id, 'patient')}></button>
                <button className="las la-comment-dots border-0 bg-transparent text-primary" onClick={() => handleCallMessagePatientLoad(contact_list.id, 'patient_message')}></button>
              </div>
            </div>)}
            {!saving && contactsCount > contactLists?.length &&
              <div className='text-center my-5'>
                <button className='btn btn-primary btn-sm' onClick={() => handleContactListsLoad({ ...filters, rpp: filters.rpp + 50, include: '*' })}>Load More 50</button>
              </div>
            }
          </> :
          <>
            {(contactLists?.length == 0 && !saving) && <p className='text-center mt-7'>No Record Found</p>}
          </>
        }
      </div>
    </div>
  )
}

PatientContact = connect((state) => {
  const { contact, filter } = state;
  return {
    saving: contact.saving,
    filters: filter.filters,
    contactLists: contact?.contact_lists,
    contactsCount: contact?.meta?.pagination?.count
  };
})(PatientContact);