import React from 'react'
import { dateTimeFormat } from '../../../../../../../itrust_common/components/HelperFunctions'
import ScrollToLastMessage from '../../../../other/ScrollToLastMessage'
import DateFormatView from '../../../../../../../itrust_common/components/others/DateFormatView'

export default function PatientMessage(props) {
  const { patientMessages } = props

  const renderStatus = (status) => {
    switch (status) {
      case "enqueued":
        return <span className="badge rounded bg-sent">Sent</span>
      case "processed":
        return <span className="badge rounded bg-sent">Sent</span>
      case "failed":
        return <span className="badge rounded bg-failed">Failed</span>
      case "delivered":
        return <span className="badge rounded bg-sent">Sent</span>
      case "cancelled":
        return <span className="badge rounded bg-cancelled">Cancelled</span>
      case "sent":
        return <span className="badge rounded bg-sent">Sent</span>
      default:
    }
  }

  const renderMessages = (message) => {
    const { staff_attributes, store_attributes } = message
    return (
      <>
        {message.sms_type === "outgoing" ?
          <div className='d-flex flex-column align-items-end'>
            <div className='d-flex justify-content-end mt-4'>
              <span className='outgoing-messages p-4 bg-primary shadow text-white text-break'>{message.body}</span>
            </div>
            <small className='pt-2 px-2'>
              <DateFormatView dateTime={message.send_at} /> | {renderStatus(message.status)}</small>
            <small className="rounded px-2 text-right">
              {staff_attributes && `${staff_attributes.name} | ${store_attributes && store_attributes.name}`}
            </small>
            {message.category === null && <>
              <small className="text-secondary">
                <i className="las la-robot m-1"></i>
                Automated {`${message.message_template_attributes?.name ? `| ${message.message_template_attributes?.name}` : ''}`}
              </small><br />
            </>}
          </div> :
          <div>
            <div className='d-flex justify-content-start mt-4'>
              <span className='incoming-messages p-4 bg-light text-dark text-break'>{message.body}</span>
            </div>
            <small>
              <DateFormatView dateTime={message.send_at} />
            </small><br />
          </div>
        }
      </>
    )
  }

  return (
    <div className='position-relative'>
      <div className='calling-inner-height p-3 overflow-auto bg-white'>
        {patientMessages.map(message => renderMessages(message))}
      </div>
      <div className='position-absolute scroll-bottom-btn'>
        <ScrollToLastMessage key={patientMessages.length} chatContainerClass="calling-inner-height" />
      </div>
    </div>
  )
}
