import React from 'react';
import DisplayField from '../../../../../itrust_common/components/others/DisplayField'
import internalExam from '../../../../../itrust_common/assets/static/images/internal-exam.png'
import hunteyecareInternalExam from '../../../../../itrust_common/assets/static/images/hunteyecare-internal-exam.png'
import CanvasDrawing from '../../../../../itrust_common/components/others/CanvasDrawing';
import { buildHashString } from "../../../../../itrust_common/components/HelperFunctions"
import DateFormatView from '../../../../../itrust_common/components/others/DateFormatView';

let Internal = props => {
  const { fieldsValues, template, isPdfView, store } = props;
  const internalRow = template.fields.ou_post_pole.visible === "true" || template.fields.ou_vessels.visible === "true" ||
    template.fields.ou_vitreous.visible === "true" || template.fields.ou_periphery.visible === "true" ||
    template.fields.ou_macula.visible === "true" ? ["od", "os", "ou"] : ["od", "os"]
  const internalCanvasWidth = isPdfView ? 341 : 495;
  const internalCanvasHeight = isPdfView ? 105 : 150;
  return (
    <div className="row">
      {
        template.fields.drawing.visible === 'true' &&
        <div className={`col-5 ${isPdfView && 'pdf-responsive-col-6'} pdf-nobreak`}>
          <CanvasDrawing
            isTemplate={true}
            wrapperClasses="bg-light rounded text-center pt-3 pb-5 mt-5"
            className="mx-auto"
            canvasRef="internalCanvasRef"
            canvasWidth={internalCanvasWidth}
            canvasHeight={internalCanvasHeight}
            imgSrc={window.location.host.split('.')[0] == 'hunteyecare' ? hunteyecareInternalExam : internalExam}
            disabled={true}
            isMarkCompleted={fieldsValues.is_marked_complete}
            defaultData={fieldsValues.draw_data ? fieldsValues.draw_data : { height: "105px", width: "341px", lines: [] }}
          />
        </div>
      }

      {
        template.fields.dialation_test.visible === 'true' &&
        <div className={`col-7 ${isPdfView && 'pdf-responsive-col-6'}`}>
          <div className="row">
            <DisplayField
              isVisible={true}
              label={"Dilation Test:"}
              value={fieldsValues.is_dialation_required ? 'Yes' : 'No'}
              className="col-2 py-4"
            />

            {fieldsValues.is_dialation_required ? [
              <DisplayField
                isVisible={true}
                label={"Drop Used:"}
                value={fieldsValues.drops_used && buildHashString(fieldsValues.drops_used)}
                className={`col-2 py-4 ${isPdfView && 'pdf-responsive-col-6'}`}
              />,
              <DisplayField
                isVisible={true}
                label={"Test Used:"}
                value={fieldsValues.tests_performed && buildHashString(fieldsValues.tests_performed)}
                className={`col-2 py-4 ${isPdfView && 'pdf-responsive-col-4'}`}
              />,
              <DisplayField
                isVisible={template.fields.instilled_at.visible}
                label={`${template.fields.instilled_at.custom_label}:`}
                value={<DateFormatView dateTime={fieldsValues.instilled_at} timezone={store?.timezone} />}
                className={`py-4 ${isPdfView ? 'col-12' : 'col-2'}`}
              />,
              <DisplayField
                isVisible={true}
                label={"OD:"}
                value={fieldsValues.dialation_od}
                className={`py-4 ${isPdfView ? 'col-6' : 'col-2'}`}
              />,
              <DisplayField
                isVisible={true}
                label={"OS:"}
                value={fieldsValues.dialation_os}
                className={`py-4 ${isPdfView ? 'col-6' : 'col-2'}`}
              />]
              :
              <DisplayField
                isVisible={template.fields.reason.visible}
                label={`${template.fields.reason.custom_label}:`}
                value={fieldsValues.dialation_inactive_reason}
                className="col-10 py-4"
              />
            }
            {fieldsValues.comments && <DisplayField
              isVisible={template.fields.comments.visible}
              label={`${template.fields.comments.custom_label}:`}
              value={fieldsValues.comments}
              className="col-12 py-4"
            />}
          </div>
        </div>
      }

      <div className="col-12 pt-6">
        <div className="overview-table mb-3">
          <table className="table table-bordered bg-light table-hover shadow-none border border-muted">
            <thead className="text-dark border border-muted">
              <tr className="text-center">
                <th scope="col"></th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_cd.visible}
                    label={template.fields.od_cd.custom_label.split(' ').slice(1).join(' ')}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_post_pole.visible}
                    label={template.fields.od_post_pole.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_vessels.visible}
                    label={template.fields.od_vessels.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_vitreous.visible}
                    label={template.fields.od_vitreous.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_periphery.visible}
                    label={template.fields.od_periphery.custom_label}
                  />
                </th>
                <th scope="col">
                  <DisplayField
                    isVisible={template.fields.od_macula.visible}
                    label={template.fields.od_macula.custom_label}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {internalRow.map(type =>
                <tr className="text-center">
                  <td>
                    <h6>{type.toUpperCase()}</h6>
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues[`${type}_cd`]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.post_pole[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.vessels[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.vitreous[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.periphery[type]}
                    />
                  </td>
                  <td>
                    <DisplayField
                      isVisible={true}
                      value={fieldsValues.macula[type]}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  )
}


export default Internal;
