import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { checkIsVisible, getTemplateSupportedField } from '../../../itrust_common/components/HelperFunctions'
import { tabChange } from '../../../itrust_common/redux/actions/otherActions'
import { Tabs } from './CommonFunctions'

function ExamTabs(props) {
  const { currentUser, exam, medicalRxes, activeTab, handleScrollToExamModules, examViewModern, setDisabledScrollEvent, disabledScrollEvent } = props

  const { template: { hpi, social_history, medical_history, ros, preliminary_binocular_testing, refraction_contact_lens_exam, external_internal_exam, additional_tests, procedures_and_impressions, finalize_prescription, attached_documents }, } = exam
  const dispatch = useDispatch()
  const firstTab = getTemplateSupportedField(exam.template)

  useEffect(() => {
    if (currentUser.role === 'non_admin' && exam.status === "finalized") {
      handleTabChange(1)
    }
  }, [exam.status])

  useEffect(() => {
    if (currentUser.role === 'non_admin' && exam.status === "finalized") {
      handleTabChange(1)
    }
    else {
      handleTabChange(examViewModern ? firstTab : 1)
    }
  }, [exam.settings_exam_template_id])

  useEffect(() => {
    if (disabledScrollEvent) {
      handleScrollToSection(disabledScrollEvent)
      setTimeout(() => setDisabledScrollEvent(false), 700);
    }
  }, [disabledScrollEvent])

  const handleTabChange = (data, tabClick) => {
    dispatch(tabChange({ exam: data }))
    if (data == firstTab) {
      handleScrollToExamModules()
    }
    if (examViewModern && tabClick) {
      setDisabledScrollEvent(data)
    }
  }

  const handleScrollToSection = (tab) => {
    // onClick tab scroll to section
    const sectionId = Tabs.find(item => item.tab === tab)?.id
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  return (
    <div className="text-nowrap overflow-auto nav-tabs-view ml-0 border border-radius-6 px-3 mt-4 bg-white">
      <ul className="nav nav-tabs exam-tabs text-nowrap overflow-auto flex-nowrap" id="myTab" role="tablist">
        {!examViewModern && <li className="nav-item" onClick={() => handleTabChange(1, true)}>
          <a className={`p-3 mr-3 tab-link ${activeTab == 1 && 'active'}`} id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">
            Overview
          </a>
        </li>}
        {(!(currentUser.role === 'non_admin' && exam.status === "finalized") || (currentUser.role === "non_admin" && currentUser.id === exam.staff_attributes.id)) &&
          <>
            {hpi && checkIsVisible(hpi.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(2, true)}>
                <a className={`py-3 px-6 mx-0 tab-link ${activeTab == 2 && 'active'}`} id="history-present-illness-tab" data-toggle="tab" href="#history-present-illness" role="tab" aria-controls="history-present-illness" aria-selected="false">
                  HPI
                </a>
              </li>
            }
            {social_history && checkIsVisible(social_history.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(3, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 3 && 'active'}`} id="social-history-tab" data-toggle="tab" href="#social-history" role="tab" aria-controls="social-history" aria-selected="false">
                  Social History
                </a>
              </li>
            }
            {medical_history && checkIsVisible(medical_history.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(4, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 4 && 'active'}`} id="medical-history-tab" data-toggle="tab" href="#medical-history" role="tab" aria-controls="medical-history" aria-selected="false">
                  Medical History
                </a>
              </li>
            }
            {ros && checkIsVisible(ros.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(5, true)}>
                <a className={`py-3 px-6 mx-0 tab-link ${activeTab == 5 && 'active'}`} id="ros-tab" data-toggle="tab" href="#ros" role="tab" aria-controls="ros" aria-selected="false">
                  ROS
                </a>
              </li>
            }
            {preliminary_binocular_testing && checkIsVisible(preliminary_binocular_testing.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(6, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 6 && 'active'}`} id="preliminary-binocular-tab" data-toggle="tab" href="#preliminary-binocular" role="tab" aria-controls="preliminary-binocular" aria-selected="false">
                  Preliminary/ Binocular
                </a>
              </li>
            }
            {refraction_contact_lens_exam && checkIsVisible(refraction_contact_lens_exam.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(7, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 7 && 'active'}`} id="refraction-contact-lens-tab" data-toggle="tab" href="#refraction-contact-lens" role="tab" aria-controls="refraction-contact-lens" aria-selected="false">
                  Refraction/ Contact Lens
                </a>
              </li>
            }
            {external_internal_exam && checkIsVisible(external_internal_exam.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(8, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 8 && 'active'}`} id="external-internal-tab" data-toggle="tab" href="#external-internal" role="tab" aria-controls="external-internal" aria-selected="false">
                  External/ Internal
                </a>
              </li>
            }
            {additional_tests && checkIsVisible(additional_tests.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(9, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 9 && 'active'}`} id="additional-tests-tab" data-toggle="tab" href="#additional-tests" role="tab" aria-controls="additional-tests" aria-selected="false">
                  Additional Tests
                </a>
              </li>
            }
            {procedures_and_impressions && checkIsVisible(procedures_and_impressions.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(10, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 10 && 'active'}`} id="impression-plan-tab" data-toggle="tab" href="#impression-plan" role="tab" aria-controls="impression-plan" aria-selected="false">
                  Procedure Impression/Plan
                </a>
              </li>
            }
            {finalize_prescription && checkIsVisible(finalize_prescription.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(11, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 11 && 'active'}`} id="finalize-prescription-tab" data-toggle="tab" href="#finalize-prescription" role="tab" aria-controls="finalize-prescription" aria-selected="false">
                  <span className="mr-3">Finalize Prescription</span>
                  {exam.finalized_glasses_attributes.length > 0 &&
                    <span className="badge badge-info badge-pill mr-3"> {exam.finalized_glasses_attributes.length} </span>
                  }
                  {medicalRxes.length > 0 &&
                    <span className="badge bg-gray-600 light-badge text-light badge-pill mr-3"> {medicalRxes.length} </span>
                  }
                  {exam.finalized_lenses_attributes.length > 0 &&
                    <span className="badge badge-warning badge-pill"> {exam.finalized_lenses_attributes.length} </span>
                  }
                </a>
              </li>
            }
            {attached_documents && checkIsVisible(attached_documents.visible) &&
              <li className="nav-item" onClick={() => handleTabChange(12, true)}>
                <a className={`p-3 mr-3 tab-link ${activeTab == 12 && 'active'}`} id="attached-docs-tab" data-toggle="tab" href="#attached-docs" role="tab" aria-controls="attached-docs" aria-selected="false">
                  Attached Docs
                  {exam.patient_documents_attributes.length > 0 &&
                    <span className="badge badge-info badge-pill ml-4 mr-2"> {exam.patient_documents_attributes.length} </span>
                  }
                </a>
              </li>
            }
          </>}
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { other, auth, exam: { exam }, medicalRx, setting: { orgConfig } } = state;
  return {
    exam: exam,
    activeTab: other.activeTab.exam,
    medicalRxes: medicalRx.medical_rxes,
    currentUser: auth.user,
    examViewModern: orgConfig.org_config?.exam_settings.view_mode == 'modern',
  }
}

export default connect(mapStateToProps)(ExamTabs)