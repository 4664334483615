import React from 'react'
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import * as $ from 'jquery';
import Alert from '../../../../itrust_common/components/Alert';
import { useHistory } from 'react-router-dom';
import RpaClaimSubmitButton from '../../../../containers/authorised/addOns/rpa/claimForm/submitClaim/RpaClaimSubmitButton';
import ClaimFormDownload from './ClaimFormDownload';
import ClaimFormSync from './ClaimFormSync';
import DateFormatView from '../../../../itrust_common/components/others/DateFormatView';

function ClaimFormHeader(props) {
  const { dispatch, order, trizettoSending, ediClearingHouseConfig, onTrizettoSend, userRole, rpaConfig } = props
  const history = useHistory()

  const handleSendToTrizetto = () => {
    if (ediClearingHouseConfig.visible && (ediClearingHouseConfig.status === 'inactive' || ediClearingHouseConfig.status === 'in_progress')) {
      history.push(`/add-ons/edi-clearing-house/${['super_admin', 'itrust_admin'].includes(userRole) ? 'settings' : 'graph-report'}`)
      $('#orderForm-modal').modal('hide');
    }
    else {
      dispatch(submit("1500ClamForm"))
      setTimeout(() => {
        let invalidInputs = (document.getElementsByClassName("is-invalid")?.length || document.getElementsByClassName("invalid-feedback")?.length)
        if (invalidInputs) {
          Alert.warning("Please fill the required information.")
        } else {
          onTrizettoSend()
        }
      }, 500)
    }
  }

  return (
    <div className="clam-form-actions-wrap w-100 bg-light px-md-7 px-lg-0 pb-6">
      <div className="d-flex flex-row justify-content-between align-items-center mx-auto">
        <div>
          {!order.rpa_claim_exist && <>
            <button className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" onClick={handleSendToTrizetto}>
              {trizettoSending ? "Sending..." : ediClearingHouseConfig.visible ? "Send to iTRUST EDI Clearinghouse" : "Send to Trizetto"}
            </button>
          </>}
          {rpaConfig?.visible && order.rpa_claim_exist && <RpaClaimSubmitButton order={order} />}
          {order.claim_submission_at && <span>Previous Claim Send on <DateFormatView dateTime={order.claim_submission_at} /></span>}
        </div>
        <div>
          <ClaimFormDownload />
          <ClaimFormSync />
          <button type="submit" className="btn btn-primary my-lg-0 my-md-2 ml-md-5" onClick={() => dispatch(submit("1500ClamForm"))}>Save</button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation } } = state

  return {
    rpaConfig: organisation.add_ons_config?.type?.rpa
  }
}

export default connect(mapStateToProps)(ClaimFormHeader)