
import React, { Component, useEffect } from 'react';
import { reduxForm, change, submit } from 'redux-form';
import { Field } from 'redux-form';
import { connect, useDispatch } from 'react-redux';
import * as $ from 'jquery';

import { DateAndTimePicker, Textarea, AutoCompleteSelect, DatePicker } from '../../../../itrust_common/components/form';
import { dateFormat, formatPhoneNumber, getCustomLabel, getShortName, getTemplateSupportedField } from '../../../../itrust_common/components/HelperFunctions';

import { examEdit, examLoad, examTemplateSwitched } from '../../../../redux/actions/exam/examActions';
import { Link } from 'react-router-dom';
import Alert from '../../../../itrust_common/components/Alert';
import DisabledLoader from '../../../../other/DisabledLoader';
import { tabChange } from '../../../../itrust_common/redux/actions/otherActions';
import { required } from '../../../../itrust_common/components/form/FormValidations';

function ExamHeaderForm(props) {
  const { defaultVisible, handleSubmit, examTemplates, exam, selectedExamId, selectedExamTemplateId, providers, patient, currentUserRole, templateSwitchSaving, visionInsuranceTemplate, medicalInsuranceTemplate, examViewModern, handleScrollToExamModules } = props;
  const dispatch = useDispatch()

  const handleExamEdit = (id) => {
    dispatch(examEdit(id));
    dispatch(examLoad(id, { include: '*' }));
  }

  useEffect(() => {
    // Exam template change tabs UI fixes
    const firstTab = getTemplateSupportedField(exam.template)
    dispatch(tabChange({ exam: examViewModern ? firstTab : 1 }))
    handleScrollToExamModules()
  }, [exam.settings_exam_template_id])

  const handleTemplateChange = (value) => {
    Alert.confirm(<div><strong>Warning:</strong> You are changing the exam template, and all recently entered exam data will be removed. Would you like to proceed?</div>,
      (res) => {
        if (res) {
          dispatch(examTemplateSwitched(true))
          dispatch(change('examHeaderForm', 'settings_exam_template_id', value));
          setTimeout(() => dispatch(submit("examHeaderForm")), 500)
        }
      }
    )
  }

  const handleExamDateChange = () => {
    setTimeout(() => dispatch(submit('examHeaderForm')), 500)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="panel-group" id="accordion">
        <div className="panel panel-default">
          <div id="collapseOne" className={`panel-collapse collapse ${defaultVisible ? 'show' : ''}`}>
            <div className="panel-body">
              <div className="bg-white w-100 d-flex rounded shadow-sm my-5">
                <div className="border-right border-light text-center p-5">
                  {getShortName(patient, 'mr-5 width-6 height-6', 'mr-5 width-6 height-6 font-size-40')}
                  <h5 className="mt-2 text-nowrap">
                    <Link to={`/patients/${exam.patient_attributes.id}/overview`} className="btn-link" onClick={() => $('#editExamForm-modal').modal('hide')}>{patient.name}</Link>
                  </h5>
                </div>
                <div className="p-6">
                  <div>
                    <span className="text-nowrap">DOB & Age:</span>
                    <span className="text-muted pl-3 text-nowrap">{dateFormat(exam.patient_attributes.date_of_birth)} | {exam.patient_attributes.age}</span>
                  </div>
                  <div>
                    <span>Phone:</span>
                    <span className="text-muted text-muted pl-3 text-nowrap">{formatPhoneNumber(exam.patient_attributes.cell_phone)}</span>
                  </div>
                  <div>
                    <span>Email:</span>
                    <span className="text-muted text-muted pl-3 text-nowrap">{exam.patient_attributes.email}</span>
                  </div>
                  <div>
                    <span className="text-nowrap">{visionInsuranceTemplate && getCustomLabel(visionInsuranceTemplate, 'insurance')}:</span>
                    <span className="text-muted text-nowrap text-muted pl-3 text-nowrap">{exam.patient_attributes.patient_vision_insurance && exam.patient_attributes.patient_vision_insurance.insurance_name}</span>
                  </div>
                  <div>
                    <span className="text-nowrap">{medicalInsuranceTemplate && getCustomLabel(medicalInsuranceTemplate, 'insurance')}:</span>
                    <span className="text-muted text-nowrap text-muted pl-3 text-nowrap">{exam.patient_attributes.patient_medical_insurance && exam.patient_attributes.patient_medical_insurance.insurance_name}</span>
                  </div>
                </div>
                <div className="border-left border-light p-5 w-100">
                  <div className="row">
                    <div className="col-4">
                      {/* Header chief complaint disabled removed due to ui issue !! ${isHpiTabVisible ? 'disabled' : ''} !!*/}
                      <Field name="hpis_attributes[0][chief_complaint]" rows={3} label="Chief Complaint" component={Textarea} className={`form-control`} wrapperClasses="form-group" onBlur={() => setTimeout(() => dispatch(submit("examHeaderForm")), 500)} disabled={(currentUserRole === 'non_admin' && exam.status === "finalized") ? true : false} />
                    </div>
                    <div className='col-2'>
                      <Field name="staff_id" label="Exam Provider" component={AutoCompleteSelect} data={providers} textField="name" valueField="id" hideResetButton={true} displayName="provider_name" wrapperClasses="form-group" disabled={(currentUserRole === 'non_admin' && exam.status === "finalized") ? true : false} onChangeHook={() => setTimeout(() => dispatch(submit("examHeaderForm")), 500)} />

                      <Field name='recall_attributes.recall_reminder_date' label="Recall Date" component={DatePicker} min={new Date()} wrapperClasses='form-group' onChangeHook={() => setTimeout(() => dispatch(submit("examHeaderForm")), 500)} disabled={(currentUserRole === 'non_admin' && exam.status === "finalized") ? true : false} />
                    </div>
                    <div className="col-2" disabled={(currentUserRole === 'non_admin' && exam.status === "finalized") ? true : false}>
                      <div className="form-group">
                        <label htmlFor="formExamTemplaeSelection">Template Selection </label>
                        <select className="form-control" value={selectedExamTemplateId} id="formExamTemplaeSelection" onChange={(e) => handleTemplateChange(e.target.value)} disabled={(currentUserRole === 'non_admin' && exam.status === "finalized") ? true : false}>
                          {examTemplates.map((examTemplate) => <option value={examTemplate.id}>{examTemplate.name}</option>)}
                        </select>
                      </div>
                    </div>
                    <Field name='exam_date' label="Exam Date" component={DateAndTimePicker} wrapperClasses='form-group col-2' validate={[required]} onChangeHook={(e) => handleExamDateChange(e)} disabled={(currentUserRole === 'non_admin' && exam.status === "finalized") ? true : false} />
                    <div className="col-2">
                      <div className="form-group">
                        <label htmlFor="formExamSelection">Exam Selection </label>
                        <select className="form-control" value={selectedExamId} id="formExamSelection" onChange={(e) => handleExamEdit(e.target.value)} >
                          {exam.all_exams && exam.all_exams.map((exam) => <option value={exam.id}>{dateFormat(exam.exam_date)}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DisabledLoader saving={templateSwitchSaving} wrapperClasses="full-screen-loader" />
    </form>
  )
}

ExamHeaderForm = reduxForm({
  form: 'examHeaderForm',
  enableReinitialize: true,
})(ExamHeaderForm)

ExamHeaderForm = connect(state => {
  const { currentStore, exam, setting: { orgConfig, examTemplate, patientTemplate: { patient_template } } } = state
  const patientInsuranceData = patient_template.personal_information?.insurance_detail?.fields
  return {
    visionInsuranceTemplate: patientInsuranceData?.vision_insurance?.fields,
    medicalInsuranceTemplate: patientInsuranceData?.medical_insurance?.fields,
    exam: exam.exam,
    templateSwitchSaving: exam.templateSwitchSaving,
    providers: currentStore.staffs,
    examTemplates: examTemplate.exam_templates,
    // exams: exam.exams,
    selectedExamId: exam.editing && exam.editing.id,
    selectedExamTemplateId: exam.exam.settings_exam_template_id,
    examViewModern: orgConfig.org_config?.exam_settings.view_mode == 'modern',
    initialValues: {
      id: exam.exam.id,
      provider_name: exam.exam.provider_name,
      staff_id: exam.exam.staff_id,
      exam_date: exam.exam.exam_date,
      hpis_attributes: exam.exam.hpis_attributes,
      recall_attributes: { recall_reminder_date: exam.exam?.patient_attributes?.recall_reminder_date }, // as per BE requirements
      settings_exam_template_id: exam.exam.settings_exam_template_id
    }
  }
})(ExamHeaderForm)

export default ExamHeaderForm;
