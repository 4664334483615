import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, change} from 'redux-form';
import {Input, Textarea, AutoCompleteSelect, RadioGroup} from '../../../../../../itrust_common/components/form'
import DateFormatView from '../../../../../../itrust_common/components/others/DateFormatView';

let LabOrderForm = props => {
  const { handleSubmit, updatedAt, form, pdfAttributes } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex align-items-end">
        <Field label="Send Securely"
          name="contact_id"
          displayName="company_name"
          component={AutoCompleteSelect}
          onChangeHook={(data) =>
            { props.dispatch(change(form, 'account_number', data.account_number)) }
          }
          klass="Contact"
          textField="company_name"
          valueField="id"
          placeholder=""
          className="form-control"
          wrapperClasses="col-6 form-group"
          labelClasses="w-100" />

        <span className="mb-5">Created At: <span className="text-muted">{updatedAt && <DateFormatView dateTime={updatedAt} />}</span></span>
      </div>

      <Field name="account_number" label="Account Number" component={Input} className="form-control" wrapperClasses="col-12 form-group" />
      <Field name="message" label="Message to Lab" component={Textarea} rows="5" className="form-control" wrapperClasses="col-12 form-group" />
      <RadioGroup name="is_urgent" options={[{label: "Yes", value: true}, {label: "No", value: false}]}  label="Mark as urgent" wrapperClasses="col-6 pb-7" />
      { pdfAttributes &&
        <a download target="_blank" href={ pdfAttributes.file.url } alt={ pdfAttributes.filename } className="btn btn-outline-primary mr-5">
          <span className="d-flex"><i className='la la-cloud-download mr-3' />Download PDF</span>
        </a>
      }
    </form>
  )
}

LabOrderForm = reduxForm({
  form: 'eyeglassLabOrderForm',
  enableReinitialize: true,
})(LabOrderForm)


LabOrderForm = connect(state=> {
  const { eyeglassLabOrder } = state
  return {
    updatedAt: eyeglassLabOrder.eyeglass_lab_order.updated_at,
    pdfAttributes: eyeglassLabOrder.eyeglass_lab_order.pdf_attributes,
    initialValues: {
      is_urgent: false,
      ...eyeglassLabOrder.eyeglass_lab_order
    }
  }
})(LabOrderForm)

export default LabOrderForm;
