import React, { Component } from 'react';
import { connect } from 'react-redux';
import Moment from 'moment';
import * as $ from 'jquery';
import { DatePicker } from 'react-widgets';

import { filterByKey, humanize, numberToCurrency } from '../../../../itrust_common/components/HelperFunctions';

import { orderPaymentUpdate, orderPaymentCreate, orderPaymentsLoad } from '../../../../redux/actions/order/orderPaymentActions';
import { orderLoad } from '../../../../redux/actions/order/orderActions';

import PaymentForm from './PaymentForm'
import Alert from '../../../../itrust_common/components/Alert';

class RefundsButtonForm extends Component {

  async handlePaymentSubmit(data) {
    const { order } = this.props
    if (!data.amount) {
      Alert.warning("Please enter amount.")
    }
    else {
      if (data.mode_of_payment == 'online' && data.payment_type == 'refund') {
      }

      if (data.id)
        await this.props.dispatch(orderPaymentUpdate(order.id, data))
      else
        await this.props.dispatch(orderPaymentCreate(order.id, data))
      this.props.dispatch(orderLoad(order.id, { include: '*' }))
    }
  }

  handlePaymentEditCancel() {
    $("#orderRefundForm-modal").modal('hide')
  }

  componentDidUpdate() {
    if (!this.props.paymentEditing) {
      $("#orderRefundForm-modal").modal('hide')
    }
  }

  async handleTabChange(data) {
    const { order } = this.props
    switch (data) {
      case 'online':
      case 'device':
      case 'link':
        this.props.dispatch(orderPaymentsLoad(order.id, { filter: { mode_of_payment: data, payment_type: 'sale' }, include: ['staff'] }))
        break;
      default:
        this.props.dispatch(orderPaymentsLoad(order.id, { filter: { payment_type: 'refund' }, include: ['staff'] }))
    }
  }

  onlineRefund(payment) {
    const { order, user } = this.props;
    let data = {
      amount: payment.amount,
      orders_order_id: order.id,
      mode_of_payment: payment.mode_of_payment,
      payment_date: Moment(new Date()).format("YYYY/MM/DD"),
      payment_type: 'refund',
      staff_id: user.id,
      prior_authorization_number: order.prior_authorization_number,
      order_payment_id: payment.id,
      online_payment_details: { payment_intent_id: payment.online_payment_details.payment_intent_id },
    }
    this.handlePaymentSubmit(data)
  }

  render() {
    const { paymentEditing, payments, organisation, user, itrustPayConfig } = this.props;
    const cashPayments = filterByKey(payments, 'mode_of_payment', 'cash')
    const checkPayments = filterByKey(payments, 'mode_of_payment', 'check')
    const cardPayments = filterByKey(payments, 'mode_of_payment', 'card')
    const onlinePayments = filterByKey(payments, 'mode_of_payment', 'online')
    const DevicePayments = filterByKey(payments, 'mode_of_payment', 'device')
    const PayByTextPayments = filterByKey(payments, 'mode_of_payment', 'link')
    return (
      <div className="inner-modal">
        <div className="modal refund-modal" id="orderRefundForm-modal">
          <div className="modal-dialog">
            <div className="modal-content border-0 shadow">
              <div className="modal-header p-5">
                <h6 className="modal-title">
                  Refunded Payment Details
                </h6>
                <button type="button" className="btn btn-outline-primary mr-3" onClick={this.handlePaymentEditCancel.bind(this)}>
                  Cancel
                </button>
              </div>
              <div className="modal-body py-5 px-0">
                <ul className="nav nav-tabs border-bottom d-flex flex-nowrap overflow-auto" id="myTab" role="tablist">
                  <li className="nav-item" onClick={this.handleTabChange.bind(this, 'refund')}>
                    <a className="p-5 tab-link text-nowrap active" id="Refund-tab" data-toggle="tab" href="#RefundPayments" role="tab" aria-controls="RefundPayments" aria-selected="true">
                      Make Refund
                    </a>
                  </li>
                  {organisation.subscription_plan !== "enterprise" && itrustPayConfig?.status !== 'inactive' && itrustPayConfig?.visible &&
                    <>
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 'online')}>
                      <a className="p-5 tab-link text-nowrap" id="OnlineRefundPayments-tab" data-toggle="tab" href="#OnlineRefundPayments" role="tab" aria-controls="OnlineRefundPayments" aria-selected="false">
                        Make Online Refund
                      </a>
                    </li>
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 'device')}>
                      <a className="p-5 tab-link text-nowrap" id="DeviceRefundPayments-tab" data-toggle="tab" href="#DeviceRefundPayments" role="tab" aria-controls="DeviceRefundPayments" aria-selected="false">
                        Make Device Refund
                      </a>
                    </li>
                    <li className="nav-item" onClick={this.handleTabChange.bind(this, 'link')}>
                      <a className="p-5 tab-link text-nowrap" id="PayByTextRefundPayments-tab" data-toggle="tab" href="#PayByTextRefundPayments" role="tab" aria-controls="PayByTextRefundPayments" aria-selected="false">
                        Make Pay By Text Refund
                      </a>
                    </li>
                    </>
                  }
                  <li className="nav-item" onClick={this.handleTabChange.bind(this, 'cash')}>
                    <a className="p-5 tab-link text-nowrap" id="CashRefundPayments-tab" data-toggle="tab" href="#CashRefundPayments" role="tab" aria-controls="CashRefundPayments" aria-selected="true">
                      Cash Payments
                    </a>
                  </li>
                  <li className="nav-item" onClick={this.handleTabChange.bind(this, 'check')}>
                    <a className="p-5 tab-link text-nowrap" id="CheckRefundPayments-tab" data-toggle="tab" href="#CheckRefundPayments" role="tab" aria-controls="CheckRefundPayments" aria-selected="false">
                      Check Payments
                    </a>
                  </li>
                  <li className="nav-item" onClick={this.handleTabChange.bind(this, 'card')}>
                    <a className="p-5 tab-link text-nowrap" id="CardRefundPayments-tab" data-toggle="tab" href="#CardRefundPayments" role="tab" aria-controls="CardRefundPayments" aria-selected="false">
                      Card Payments
                    </a>
                  </li>
                </ul>
                <div className="tab-content mx-auto">
                  <div className="tab-pane active" id="RefundPayments" role="tabpanel" aria-labelledby="RefundPayments-tab">
                    <div className="p-6">
                      {
                        paymentEditing && paymentEditing.id &&
                        <PaymentForm form="orderRefundForm" onSubmit={this.handlePaymentSubmit.bind(this)} paymentType={'refund'} />
                      }
                    </div>
                  </div>
                  <div className="tab-pane" id="CashRefundPayments" role="tabpanel" aria-labelledby="CashRefundPayments-tab">
                    {this.renderPayments(cashPayments, 'cash')}
                  </div>
                  <div className="tab-pane" id="CheckRefundPayments" role="tabpanel" aria-labelledby="CheckRefundPayments-tab">
                    {this.renderPayments(checkPayments, 'check')}
                  </div>
                  <div className="tab-pane" id="CardRefundPayments" role="tabpanel" aria-labelledby="CardRefundPayments-tab">
                    {this.renderPayments(cardPayments, 'card')}
                  </div>
                  {organisation.subscription_plan !== "enterprise" && itrustPayConfig?.status !== 'inactive' && itrustPayConfig?.visible &&
                  <>
                    <div className="tab-pane" id="OnlineRefundPayments" role="tabpanel" aria-labelledby="OnlineRefundPayments-tab">
                      {this.renderPayments(onlinePayments, 'online')}
                    </div>
                    <div className="tab-pane" id="DeviceRefundPayments" role="tabpanel" aria-labelledby="DeviceRefundPayments-tab">
                      {this.renderPayments(DevicePayments, 'device')}
                    </div>
                    <div className="tab-pane" id="PayByTextRefundPayments" role="tabpanel" aria-labelledby="PayByTextRefundPayments-tab">
                      {this.renderPayments(PayByTextPayments, 'pay_by_text')}
                    </div>
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPayments(payments, type) {
    const { dateFormatType } = this.props
    return (
      <div className="overview-table py-7">
        <table className="table table-hover shadow-none">
          <thead className="text-dark border-bottom border-bottom-2 border-light">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Amount</th>
              {type === 'check' &&
                <th scope="col">Check #</th>
              }
              <th scope="col">Refund At</th>
              <th scope="col">Refunded By</th>
              {!['online', 'device', 'pay_by_text'].includes(type) &&
                <th scope="col">Comment</th>
              }
              {['online', 'device', 'pay_by_text'].includes(type) &&
                <th scope="col">Refund</th>
              }
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) =>
              <>
                {
                  <tr className="text-dark border-bottom border-light border-bottom-2">
                    <td className="text-center">#{payment.id}</td>
                    <td>{numberToCurrency(payment.amount)}</td>
                    {
                      type === 'check' &&
                      <td>{payment.check_details && humanize(payment.check_details.check_number)}</td>
                    }
                    <td className="w-25">
                      {['online', 'device', 'pay_by_text'].includes(type) ?
                        <>
                          {(payment.online_payment_details.refund_details?.refund_at ) &&
                            <span> {Moment(payment.online_payment_details.refund_details.refund_at).format("YYYY/MM/DD")}</span>
                          }
                        </> :
                        <DatePicker
                          value={payment.payment_date ? new Date(payment.payment_date) : null}
                          format={dateFormatType}
                          editFormat={dateFormatType}
                          placeholder={dateFormatType}
                          onChange={(date) => this.handlePaymentSubmit({ id: payment.id, payment_date: Moment(date).format("YYYY/MM/DD") })}
                        />
                      }
                    </td>
                    <td>
                      {['online', 'device', 'pay_by_text'].includes(type) ?
                        <>
                          {(payment.online_payment_details.refund_details?.charge_id) &&
                            <span>{payment.staff_attributes?.name}</span>
                          }
                        </> :
                        <span>{payment.staff_attributes?.name}</span>
                      }
                    </td>
                    {!['online', 'device', 'pay_by_text'].includes(type) && <td>{payment.notes}</td>}
                    {['online', 'device', 'pay_by_text'].includes(type) &&
                      <td>
                        {(payment.online_payment_details.refund_details && payment.online_payment_details.refund_details.charge_id) ?
                          <button type="button" className="btn btn-primary px-4 py-2 disabled">Refunded</button> :
                          <button type="submit" className={`btn btn-primary px-4 py-2`} onClick={() => this.onlineRefund(payment)}>Refund</button>
                        }
                      </td>
                    }
                  </tr>
                }
              </>
            )}
          </tbody>
        </table>
      </div>
    )

  }
}

RefundsButtonForm = connect(state => {
  const { orderPayment, order, other, auth: { user }, organisation: { organisation }, setting: { orgConfig } } = state
  return {
    organisation,
    user,
    activeTab: other.activeTab.refund,
    itrustPayConfig: organisation.add_ons_config?.type?.itrust_pay,
    paymentEditing: orderPayment.editing,
    payments: orderPayment.payments,
    order: order.order,
    dateFormatType: orgConfig.org_config?.date_format
  }
})(RefundsButtonForm)

export default RefundsButtonForm;
