import React from 'react'
import BlueCheckedCircle from "../../../../assets/static/images/blue-check-circle.svg";
import { humanize } from '../../../../itrust_common/components/HelperFunctions';
import { onBoardingTourInfoUpdate } from '../../../../redux/actions/onBoardingActions';

function TourModules(props) {
  const { userRole, onboardingGuide, dispatch, organisationStatus, onBoarding: { activeTourType } } = props
  const TourModules = ['create_appointment', 'create_patient', 'create_exam', 'create_order']

  const handleTourModuleChange = (moduleToPlay) => {
    if (activeTourType !== moduleToPlay) {
      dispatch(onBoardingTourInfoUpdate({ isTourActive: false, nextModuleToPlay: moduleToPlay }))
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center mt-3 pointer p-2 primary-hover rounded">
        <img src={BlueCheckedCircle} className="mr-3" />
        <del>Login to free trial account</del>
      </div>
      {TourModules.map((module) =>
        <div className={`d-flex align-items-center p-2 pointer primary-hover rounded`} key={module} onClick={() => handleTourModuleChange(module)}>
          {(onboardingGuide && onboardingGuide[module]) ?
            <span className='p-2'>
              <img src={BlueCheckedCircle} className="mr-3" />
              <del>{humanize(module)}</del>
            </span> :
            <>
              <i className="las la-circle font-size-30 mr-3"></i>
              <span>{humanize(module)}</span>
            </>
          }
        </div>
      )}
      {(organisationStatus === 'trial' && ["super_admin", "itrust_admin"].includes(userRole)) &&
        <div className="d-flex align-items-center pointer primary-hover rounded p-2" onClick={() => handleTourModuleChange('activate_account')}>
          {onboardingGuide?.activate_account ?
            <span className='p-2'>
              <img src={BlueCheckedCircle} className="mr-3" />
              <del>Activate Account</del>
            </span> :
            <>
              <i className="las la-circle font-size-30 mr-3"></i>
              <span>Activate Account</span>
            </>
          }
        </div>
      }
    </div>
  )
}

export default TourModules;