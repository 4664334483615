import React from 'react'
import { connect } from 'react-redux';
import { dateFormat, dateTimeFormat } from '../HelperFunctions';

function DateFormatView(props) {
  const { date, dateFormatType, notExist, dateTime, timezone } = props

  return dateTime ?
    dateTimeFormat(dateTime, timezone, dateFormatType) :
    dateFormat(date, dateFormatType) || `${notExist ?? ''}`
}

const mapStateToProps = (state) => {
  const { setting: { orgConfig } } = state;
  return {
    dateFormatType: orgConfig.org_config?.date_format,
  }
}

export default connect(mapStateToProps)(DateFormatView);