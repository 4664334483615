import React from 'react';
import * as $ from 'jquery';
import { useDispatch } from 'react-redux';
import { examUpdate } from '../../../../redux/actions/exam/examActions';
import { examFieldsConvert, examFieldModelName, additionalTestFieldIndex, examImportFieldName } from '../../../../itrust_common/components/HelperFunctions';

export default function ImportOldValues(props) {
  const { overviewName, activeExam, previousExam, examId } = props;
  const dispatch = useDispatch();

  function handleRecordId(modelName) {
    const attribute = examFieldsConvert(overviewName.id);
    switch (modelName) {
      case 'Exam::Examination':
        // In Previous Test Values Modal selected exam_id
        return activeExam;
      case 'Exam::AdditionalTest':
        const attrIndex = additionalTestFieldIndex(attribute);
        return previousExam['additional_tests_attributes'][attrIndex]['id'];
      default: return previousExam[attribute]['id'];
    }
  }

  const importData = () => {
    const modelName = examFieldModelName(overviewName.id);
    const recordId = handleRecordId(modelName);
    const importField = examImportFieldName(overviewName.id)
    let importDetails = {
      import_exam_data_hash: {
        model_name: modelName,
        record_id: recordId,
        import_field: importField,
      },
    };
    if (modelName === 'Exam::AdditionalTest') {
      importDetails = { import_exam_data_hash: { ...importDetails.import_exam_data_hash, images: ['images1', 'images2'] } };
    } else if (modelName === 'Exam::Keratometry') {
      importDetails = { import_exam_data_hash: { ...importDetails.import_exam_data_hash, images: ['od_topography_images', 'os_topography_images'] } };
    }
    dispatch(examUpdate({ id: examId, ...importDetails }, { include: '*' }))
      .then(() => $(`#${overviewName.id}`).modal('toggle'));
  };

  return (
    <button type="button" className="btn btn-outline-primary" onClick={importData}>
      Import Selected
    </button>
  );
}
