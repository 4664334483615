import {handleAPI, API_VERSION_ONE_ADMIN} from '../../../utils/apiUtils';
const parent = 'SETTING'


export function orgConfigLoad(filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/org_config`;
  const method = 'GET'

  return {
    type: `${parent}_ORG_CONFIG_LOAD`,
    payload: handleAPI(url,filters,method)
  };
}

export function orgConfigUpdate(data, filters){
  const url = `/${API_VERSION_ONE_ADMIN}/settings/org_config`;
  const method = "PUT"
  return {
    type: `${parent}_ORG_CONFIG_UPDATE`,
    payload: handleAPI(url,filters,method, {org_config: {...data}})
  }
}
