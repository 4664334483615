import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Helmet} from "react-helmet";

import {claimFormUpdate, claimFormSecureFax, trizettoSend} from '../../../redux/actions/order/claimFormActions';
import {patientReportPreview, patientReportEmail} from '../../../redux/actions/patient/patientReportActions'

import {tabChange} from '../../../itrust_common/redux/actions/otherActions';
import { filterByKey, dateFormat, numberToCurrency } from '../../../itrust_common/components/HelperFunctions';

import OrderHeaderForm from './edit/OrderHeaderForm'
import ClaimForm from './edit/ClaimForm';
import ProcedureForm from './edit/ProcedureForm';
import ContactLensForm from './edit/ContactLensForm';
import EyeglassForm from './edit/EyeglassForm';
import MiscItemForm from './edit/MiscItemForm';
import Invoice from './edit/Invoice';

import UnFinalizeButton from './moreActions/UnFinalizeButton';
import FinalizeButton from './moreActions/FinalizeButton';
import FinalizeButtonForm from './moreActions/FinalizeButtonForm';
import PaymentsButton from './moreActions/PaymentsButton';
import PaymentsButtonForm from './moreActions/PaymentsButtonForm';
import RefundsButton from './moreActions/RefundsButton';
import RefundsButtonForm from './moreActions/RefundsButtonForm';
import EditExamButton from '../buttons/EditExamButton';
import Alert from '../../../itrust_common/components/Alert';
import { ExportButton } from '../../../itrust_common/components/others';
import DeleteOrderButton from '../../../components/authorised/buttons/DeleteOrderButton';
import ImpressionForm from './edit/ImpressionForm';
import AttachedDocsForm from './edit/AttachedDocsForm';
import { orderUpdate } from '../../../redux/actions/order/orderActions';
import DuplicateOrderButton from '../buttons/DuplicateOrderButton';
import GenerateClaimFormButton from './GenerateClaimFormButton';
import { rpaClaimFormUpdate } from '../../../redux/actions/addOns/rpa/rpaClaimFormAction';
// import { getInsuranceFromRPAServices, isRpaVerifiedInsurance } from '../../../containers/authorised/addOns/other/AddOnsHelperFunctions';
import RpaClaimForm from '../../../containers/authorised/addOns/rpa/claimForm/submitClaim/RpaClaimForm';

class OrderForm extends Component{
  constructor(props){
    super(props)
    this.state={
      accordionToggle: true,
      lastScrollPos: 0
    }
  }

  handleTabChange(data){
    this.props.dispatch(tabChange({order: data}))
  }

  handleScroll(event) {
    if(event.currentTarget.scrollTop === 0) {
      this.setState({
        lastScrollPos: event.currentTarget.scrollTop,
        accordionToggle: true
      });
    } else if(this.state.lastScrollPos < event.currentTarget.scrollTop) {
      this.setState({
        lastScrollPos:event.currentTarget.scrollTop,
        accordionToggle: false
      });
    }
  }

  handleOrderSubmit(data) {
    this.props.dispatch(orderUpdate(data, { include: '*' }))
  }

  handleClaimFormSubmit(data) {
    const { order } = this.props
    if (order.rpa_claim_exist) {
      this.props.dispatch(rpaClaimFormUpdate(data))
    }
    else {
      this.props.dispatch(claimFormUpdate(order.id, data))
    }
  }

  handleClaimFormSecureFax(data){
    const { order } = this.props
    if(!order.finalized_at){
      return Alert.warning("The order must be finalized to send the claim, please finalize the order and then submit the claim")
    }
    this.props.dispatch(claimFormSecureFax(order.id, data))
  }
  handleEmailInvoice(){
    const { order } = this.props
    this.props.dispatch(patientReportEmail(order.patient_attributes.id, {report_type: "invoices", object_id: order.id}))
  }

  async handlePrintInvoice(){
    const { order } = this.props
    var res = await this.props.dispatch(patientReportPreview(order.patient_attributes.id, {report_type: "invoices", object_id: order.id}))
    window.open(res.value.data.filepath,'_blank')
  }

  handleTrizettoSend(){
    const { order } = this.props
    this.props.dispatch(trizettoSend(order.id))
    .then(()=> this.props.onSubmit({id: order.id}))
  }

  handleOnSave(){
    const {activeTab, onRemoteSubmit } = this.props
    switch(activeTab){
      case 1: onRemoteSubmit('orderProcedureForm'); break
      case 2: onRemoteSubmit('orderContactLensForm'); break
      case 3: onRemoteSubmit('orderEyeglassForm'); break
      case 4: onRemoteSubmit('orderMiscItemForm'); break
      default:
    }
  }

  getBalanceDueBgColor() {
    const { order } = this.props
    if (order.balance_due > 0) {
      return 'bg-danger'
    }
    else if (order.balance_due < 0) {
      return 'bg-success'
    }
    else {
      return 'bg-dark'
    }
  }

  getTotalPaidBgColor() {
    const { order } = this.props
    if (order.amount_paid > 0) {
      return 'bg-success'
    }
    else {
      return 'bg-dark'
    }
  }

  render(){
    const { activeTab, onSubmit, insuranceClaimForm, order, store, appFontSize, handleOrderEditCancel, dispatch, rpaClaimForm, claimFormData, rpaConfig, services, dateFormatType } = this.props;
    const contactLensHoldCount  = filterByKey(order.contact_lenses_attributes, 'is_on_hold', true).length
    const contactLensCount = order.contact_lenses_attributes && order.contact_lenses_attributes.length - contactLensHoldCount
    const eyeglassHoldCount  = filterByKey(order.eyeglasses_attributes, 'is_on_hold', true).length
    const eyeglassCount = order.eyeglasses_attributes && order.eyeglasses_attributes.length - eyeglassHoldCount
    // const isRpaSupported = isRpaVerifiedInsurance(rpaConfig, services, order.patient_insurance_attributes?.insurance_name)
    // const insurance = getInsuranceFromRPAServices(services, order.patient_insurance_attributes?.insurance_name)

    return (
      <div>
        <Helmet>
          <title>{`${order.patient_attributes.name} ${dateFormat(order.order_date, dateFormatType)} ${store.name}`}</title>
        </Helmet>
        <div className="fixed-container-modal px-5 pt-5 bg-light patient-exam-form-fixed">

          <div className="d-flex flex-row justify-content-between align-items-center text-nowrap">
            <div className="d-flex align-items-start">
              <div>
                <h5 className="mr-2">Patient Order Form</h5>
                <span className="ml-1"><strong>ID:</strong> {order.id}</span>
              </div>
              <a className="collapsed border border-primary rounded-circle ml-2" onClick={()=> this.setState({accordionToggle: !this.state.accordionToggle})} role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <i className="la la-sm la-angle-down text-primary px-2 pt-2" />
              </a>
              <div className='mx-8 align-self-center'>
                <span className={`rounded text-white mr-5 py-3 px-5 ${this.getBalanceDueBgColor()}`}>Balance Due: {numberToCurrency(order.balance_due)}</span>
                <span className={`rounded text-white py-3 px-5 ${this.getTotalPaidBgColor()}`}>Total Paid: {numberToCurrency(order.amount_paid)}</span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              {/* {
                order.status == 'finalized' && order.patient_insurance_id && !insuranceClaimForm.id &&(order.claim_amount > 0.0 || order.insurance_receivable <= 0.0) &&
                <button className="btn btn-danger mx-lg-3" onClick={order.insurance_receivable <= 0.0 ? () => Alert.confirm("This order has a receivable less than or equal to $0 and creating a 1500 claim form could lead to a claim rejection.", (res) => { if (res) this.props.dispatch(this.handleClaimFormCreate.bind(this)) }) : this.handleClaimFormCreate.bind(this)}>Generate CMS 1500</button>
              } */}
              {order.status == 'finalized' && order.patient_insurance_id && (order.claim_amount > 0.0 || order.insurance_receivable <= 0.0) && !claimFormData.id && <GenerateClaimFormButton order={order} dispatch={this.props.dispatch} handleTabChange={this.handleTabChange.bind(this)} />}

              {
                order.exam_examination_id &&
                <EditExamButton storeId={order.store_id} examId={order.exam_examination_id}>
                  <button type="button" className="btn btn-outline-primary mx-lg-3">Open Exam</button>
                </EditExamButton>
              }
              <button className="btn btn-outline-primary mx-lg-3" onClick={this.handleOnSave.bind(this)}>Save Order</button>
              {
                order.status === "finalized" &&
                <UnFinalizeButton appointmentId={order.appointment_id} orderId={order.id}>
                  <button
                    type="button"
                    className="btn btn-warning mx-lg-3">
                    Unfinalize and Edit
                  </button>
                </UnFinalizeButton>
              }
              <FinalizeButton orderStaffId={order.staff_attributes?.id} onFinalize={this.handleOnSave.bind(this)} dispatch={dispatch}>
                {
                  order.status === "finalized" ?
                  <button
                    type="button"
                    className="btn btn-success mx-lg-3">
                    Finalized
                  </button>
                  :
                  <button
                    type="button"
                    className="btn btn-outline-primary mx-lg-3">
                    Finalize Order
                  </button>
                }
              </FinalizeButton>

              <div className="dropdown d-inline-block mx-3 bg-light">
                <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  More Actions
                  <i className="ml-3 la la-angle-down la-sm"/>
                </button>
                <div className="dropdown-menu border-0 shadow rounded my-3" aria-labelledby="dropdownMenuButton">
                  <RefundsButton orderId={order.id}>
                    <a className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3" href="#">
                      <i className="la la-sm mr-3 la-history"/>
                      Refund
                    </a>
                  </RefundsButton>
                  <PaymentsButton orderId={order.id}>
                    <a className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3" href="#">
                      <i className="la la-sm mr-3 la-dollar-sign"/>
                      Payment Details
                    </a>
                  </PaymentsButton>
                  <a className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3" onClick={this.handleEmailInvoice.bind(this)}>
                    <i className="la la-sm mr-3 la-paper-plane"/>
                    Email Invoice
                  </a>
                  <a className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light py-3 pointer" onClick={this.handlePrintInvoice.bind(this)}>
                    <i className="la la-sm mr-3 la-print"/>
                    Print Invoice
                  </a>
                  <ExportButton label={<span><i className="la la-sm mr-3 la-edit"/>Download Invoice</span>} className="dropdown-item d-flex align-item-center border-bottom border-bottom-2 border-light rounded-0" path={`/v1/orders/${order.id}/invoice_pdf`} />
                  <DuplicateOrderButton orderId={order.id} >
                    <a className="dropdown-item d-flex align-items-center py-3"><i className="la la-copy la-sm mr-3" />Duplicate</a>
                  </DuplicateOrderButton>
                  <DeleteOrderButton order={order} onDeleteHook={handleOrderEditCancel}>
                    <a className="dropdown-item d-flex align-items-center text-danger py-3"><i className="la la-trash la-sm mr-3"/>Delete</a>
                  </DeleteOrderButton>
                </div>
              </div>
              <button className="btn btn-outline-primary mx-3" data-dismiss="modal">Close</button>
            </div>
          </div>

          <OrderHeaderForm handleClaimProviderSubmit={this.handleClaimFormSubmit.bind(this)} onSubmit={onSubmit} defaultVisible={this.state.accordionToggle} activeTab={activeTab} claimFormData={claimFormData} />

          <div className="text-nowrap overflow-auto nav-tabs-view ml-0 border border-radius-6 px-3 mt-4 bg-white d-flex align-items-center justify-content-between">
            <ul className="nav nav-tabs border-0" id="myTab" role="tablist">

              <li className="nav-item" onClick={this.handleTabChange.bind(this, 1)}>
                <a className={`p-3 mr-3 tab-link ${activeTab===1 && 'active'}`} href="#exam-procedures" data-toggle="tab" role="tab" aria-controls="exam-procedures" aria-selected="true">
                  Exam Procedures
                  {order.procedures_attributes && order.procedures_attributes.length > 0 &&
                    <span className="badge badge-info badge-pill mx-4"> {order.procedures_attributes.length} </span>
                  }
                </a>
              </li>

              <li className="nav-item" onClick={this.handleTabChange.bind(this, 2)}>
                <a className={ `p-3 mr-3 tab-link  ${activeTab===2 && 'active'}` } href="#contact-lens-order" data-toggle="tab" role="tab" aria-controls="contact-lens-order" aria-selected="false">
                  Contact Lens Orders
                  { contactLensCount > 0 &&
                    <span className="badge badge-info badge-pill ml-4 mr-2"> {contactLensCount} </span>
                  }
                  { contactLensHoldCount > 0 &&
                    <span className="badge badge-warning badge-pill mx-4"> {contactLensHoldCount} </span>
                  }
                </a>
              </li>
              <li className="nav-item" onClick={this.handleTabChange.bind(this, 3)}>
                <a className={ `p-3 mr-3 tab-link  ${activeTab===3 && 'active'}` } href="#eyeglass-order" data-toggle="tab" role="tab" aria-controls="eyeglass-order" aria-selected="false">
                  Eyeglass Order
                  { eyeglassCount > 0 &&
                    <span className="badge badge-info badge-pill ml-4 mr-2"> {eyeglassCount} </span>
                  }
                  { eyeglassHoldCount > 0 &&
                    <span className="badge badge-warning badge-pill mx-4"> {eyeglassHoldCount} </span>
                  }
                </a>
              </li>
              <li className="nav-item" onClick={this.handleTabChange.bind(this, 4)}>
                <a className={ `p-3 mr-3 tab-link  ${activeTab===4 && 'active'}` } href="#misc-item" data-toggle="tab" role="tab" aria-controls="misc-item" aria-selected="false">
                  Misc. Item
                  {order.misc_items_attributes && order.misc_items_attributes.length > 0 &&
                    <span className="badge badge-info badge-pill mx-4"> {order.misc_items_attributes.length} </span>
                  }
                </a>
              </li>

              <li className="nav-item" onClick={this.handleTabChange.bind(this, 5)}>
                <a className={ `p-3 mr-3 tab-link ${activeTab===5 && 'active'}` } href="#invoice" data-toggle="tab" role="tab" aria-controls="invoice" aria-selected="false">
                  Order Summary
                </a>
              </li>
              {
                (insuranceClaimForm.id || rpaClaimForm.id) &&
                <li className="nav-item" onClick={this.handleTabChange.bind(this, 6)}>
                  <a className={`p-3 mr-3 tab-link ${activeTab===6 && 'active'}`} href="#claim-1500" data-toggle="tab" role="tab" aria-controls="claim-1500" aria-selected="false">
                    Claim Form
                  </a>
                </li>
              }
              <li className="nav-item" onClick={this.handleTabChange.bind(this, 7)}>
                <a className={`p-3 mr-3 tab-link ${activeTab === 7 && 'active'}`} href="#attachedDocs" data-toggle="tab" role="tab" aria-controls="attachedDocs" aria-selected="false">
                 Attached Docs
                  {order.patient_documents_attributes?.length > 0 &&
                    <span className="badge badge-info badge-pill mx-4">{order.patient_documents_attributes.length}</span>
                  }
                </a>
              </li>
            </ul>
            <div className="d-flex">
              <span>Indicator</span>
              <span className="badge badge-info badge-pill mx-5"> Total Count </span>
              <span className="badge badge-warning badge-pill"> Item On Hold </span>
            </div>
          </div>
        </div>

        <div id="orderForm-scroll"  className={`${this.state.accordionToggle ? 'middle-container-modal-2' : 'middle-container-modal'} px-5 mb-9`}>

          <div className="tab-content overflow-auto overflow-x-hidden mt-lg-0 mt-6 order-form-header" onScroll={this.handleScroll.bind(this)}
          style={{height: '750px'}}>
            {
              activeTab===1 &&
              <div className={`${order.status === 'finalized' && 'disabled'} ${insuranceClaimForm && insuranceClaimForm.id && `${appFontSize == "large" ? 'pt-10' : 'pt-8'}`} tab-pane active`} id="exam-procedures" role="tabpanel" aria-labelledby="exam-procedures-tab">
                <ProcedureForm onSubmit={onSubmit} />
                <ImpressionForm onSubmit={onSubmit} />
              </div>
            }
            {
              activeTab===2 &&
              <div className={`${order.status === 'finalized' && 'disabled'} ${insuranceClaimForm && insuranceClaimForm.id && `${appFontSize == "large" ? 'pt-10' : 'pt-8'}`} tab-pane active`} id="contact-lens-order" role="tabpanel" aria-labelledby="contact-lens-order-tab">
                <ContactLensForm onSubmit={onSubmit}/>
              </div>
            }
            {
              activeTab===3 &&
              <div className={`${insuranceClaimForm && insuranceClaimForm.id && `${appFontSize == "large" ? 'pt-10' : 'pt-8'}`} tab-pane active`} id="eyeglass-order" role="tabpanel" aria-labelledby="eyeglass-order-tab">
                <EyeglassForm onSubmit={onSubmit}/>
              </div>
            }
            {
              activeTab===4 &&
              <div className={`${order.status === 'finalized' && 'disabled'} ${insuranceClaimForm && insuranceClaimForm.id && `${appFontSize == "large" ? 'pt-10': 'pt-8'}`} tab-pane active`} id="eyeglass-order" role="tabpanel" aria-labelledby="eyeglass-order-tab">
                <MiscItemForm onSubmit={onSubmit}/>
              </div>
            }
            {
              activeTab===5 &&
              <div className="tab-pane active" id="invoice" role="tabpanel" aria-labelledby="invoice-tab">
                <Invoice />
              </div>
            }
            {claimFormData.id && activeTab === 6 &&
              <div className={`tab-pane active ${appFontSize == "large" ? 'pt-10' : 'pt-8'}`} id="claim-1500" role="tabpanel" aria-labelledby="claim-1500-tab">
                {/* {(isRpaSupported && !insurance?.config?.form_1500) ? */}
                {(!order.vision_insurance_service?.form_1500 && order.vision_insurance_service?.insurance_payer) ?
                  <RpaClaimForm /> :
                  <ClaimForm
                    order={order}
                    onSubmit={this.handleClaimFormSubmit.bind(this)}
                    onSecureFax={this.handleClaimFormSecureFax.bind(this)}
                    onTrizettoSend={this.handleTrizettoSend.bind(this)}
                  />
                }
              </div>
            }
            {
              activeTab === 7 &&
              <div className="tab-pane active" id="attached-docs" role="tabpanel" aria-labelledby="attached-docs-tab">
                <AttachedDocsForm onSubmit={this.handleOrderSubmit.bind(this)} />
              </div>
            }
          </div>
        </div>
        {/* <InsuranceBalanceDueForm />  */}

        <PaymentsButtonForm />
        <FinalizeButtonForm />
        <RefundsButtonForm />
      </div>
    );
  }
}

OrderForm = connect(state => {
  const { other, auth, order, claimForm: { insurance_claim_form }, rpaClaimForm: { rpaClaimForm }, organisation, currentStore, rpa: { services }, setting: { orgConfig } } = state;

  return {
    appFontSize: auth.user && auth.user.font_size,
    order: order.order,
    orderEditing: order.editing,
    isFormChanged: order.isFormChanged,
    insuranceClaimForm: insurance_claim_form,
    claimFormData: order.order.rpa_claim_exist ? rpaClaimForm : insurance_claim_form,
    rpaClaimForm,
    activeTab: other.activeTab.order,
    organisation: organisation.organisation,
    store: currentStore.store,
    rpaConfig: organisation.organisation.add_ons_config?.type?.rpa,
    services,
    dateFormatType: orgConfig.org_config?.date_format,
  }
})(OrderForm)


export default OrderForm;
