import moment from "moment-timezone"
import { startRecording, stopRecoding } from "../../../support/utils/recorder"
import * as $ from 'jquery'

export async function handleStartExamRecording() {
  const res = await startRecording()
  !res && $('#editExamForm-modal').modal('hide')
  return res
}

export async function handleStopExamRecording(exam) {
  var res = await stopRecoding()
  const fileName = `${moment(new Date()).format("MM/DD/YYYY_hh:mma")}`
  res = {
    file: res.attachmentBase,
    filename: fileName,
    content_type: 'video/webm',
    byte_size: res.byte_size
  }
  return res
}

export const Tabs = [
  { id: 'history-present-illness', tab: 2 },
  { id: 'social-history', tab: 3 },
  { id: 'medical-history', tab: 4 },
  { id: 'ros', tab: 5 },
  { id: 'preliminary-binocular', tab: 6 },
  { id: 'refraction-contact-lens', tab: 7 },
  { id: 'external-internal', tab: 8 },
  { id: 'additional-tests', tab: 9 },
  { id: 'impression-plan', tab: 10 },
  { id: 'finalize-prescription', tab: 11 },
  { id: 'attached-docs', tab: 12 }
]

export const getFirstTabElementId = (firstTab) => {
  const ids = ['', '', 'history-present-illness', 'social-history', 'medical-history', 'ros', 'preliminary-binocular', 'refraction-contact-lens', 'external-internal', 'additional-tests', 'impression-plan', 'finalize-prescription', 'attached-docs']
  return ids[firstTab]
}
