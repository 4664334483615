import React from 'react'
import { dateFormat, formatSecondsInMinutes, timeFormat } from '../../../../../../../itrust_common/components/HelperFunctions'
import { Link } from 'react-router-dom'
import { getCallStatusSymbol, getCallStatus, getPatientPhoneNumber } from '../../../../other/AddOnsHelperFunctions'
import { contactLoad } from '../../../../../../../redux/actions/addOns/contactListsAction'
import { patientMessagesLoad } from '../../../../../../../redux/actions/patient/patientMessageActions'
import { callPopUpEdit, voipEdit } from '../../../../../../../redux/actions/addOns/voip/voipActions'
import DateFormatView from '../../../../../../../itrust_common/components/others/DateFormatView'

export default function Log(props) {
  const { callLog, filters, dispatch } = props

  const handleCallMessagePatientLoad = async (patientId, editType) => {
    if (editType === 'patient_message') {
      let filter = { ...filters, sort: "send_at", direction: 'asc', rpp: 999999999, include: ['patient', 'store', 'staff'] }
      await dispatch(contactLoad(patientId, { include: '*' }))
      await dispatch(patientMessagesLoad({ ...filter, patient_id: patientId, include: ['message_template'] }))
      dispatch(callPopUpEdit(editType))
      dispatch(voipEdit({ prevTab: 'logs' }))
    }
    else {
      await dispatch(contactLoad(patientId, { include: '*' }))
      dispatch(callPopUpEdit(editType))
      dispatch(voipEdit({ prevTab: 'logs' }))
    }
  }

  return (
    <>
      <div className='d-flex justify-content-between text-secondary font-size-14 my-3'>
        <span className='d-flex align-items-basl'>
          <span className={`text-primary mr-2`}>
            {getCallStatusSymbol(callLog)}
          </span>
          <span className='d-flex flex-column'>
            {callLog.patient_attributes ?
              <div className='d-flex flex-row'>
                <Link to={`/add-ons/voip/call-logs`} className="text-primary" onClick={() => dispatch(callPopUpEdit(false))}>{callLog.patient_attributes.name}</Link>
                <button className="las la-phone border-0 bg-transparent text-primary pr-0 font-size-16" onClick={() => handleCallMessagePatientLoad(callLog.patient_attributes.id, 'patient')}></button>
                <button className="las la-comment-dots border-0 bg-transparent text-primary pl-2 font-size-16" onClick={() => handleCallMessagePatientLoad(callLog.patient_attributes.id, 'patient_message')}></button>
              </div> :
              <span className='text-primary'>Anonymous</span>
            }
            {getPatientPhoneNumber(callLog)}
            <div className='d-flex'>
              {getCallStatus(callLog)}
              <span className='ml-3'>{callLog.duration && `(${formatSecondsInMinutes(callLog.duration)})`}</span>
            </div>
          </span>
        </span>
        <span className='d-flex flex-column align-items-end'>
          <span>
            <DateFormatView date={callLog.call_time} />
          </span>
          <span>{timeFormat(callLog.call_time)}</span>
        </span>
      </div>
    </>
  )
}