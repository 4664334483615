import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dateFormat } from '../../../../itrust_common/components/HelperFunctions';
import DateFormatView from '../../../../itrust_common/components/others/DateFormatView';

class ReferralListing extends Component {
  render() {
    const { referrals } = this.props;
    return (
      <div className="modal-body p-0">
        <div className="overview-table px-2 table-responsive-sm">
          <table className="table table-hover bg-white exam-table shadow-none">
            <thead className="text-dark">
              <tr>
                <th scope="col">Referral Date</th>
                <th scope="col">Referred To</th>
                <th scope="col">Referred Reason</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {referrals.length !== 0 ? referrals.map((referral) =>
                this.renderReferrals(referral)
              ) : <tr><td colSpan="4" className="text-center text-muted"> No referral Found </td></tr>}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderReferrals(referral) {
    const { handleReferralDelete, handleReferralEdit, handleReferralLoad, handleReferralResend } = this.props
    return (
      <tr key={'referral-' + referral.id} className="border-top border-top-2 border-light">
        <td>
          <DateFormatView date={referral.referral_date} />
        </td>
        <td className="text-nowrap">{referral.request_to_see}</td>
        <td>{referral.referral_reason}</td>
        <td>
          <span className="badge badge-primary mr-3 pointer" onClick={handleReferralEdit.bind(this, referral.id)}><i className="la la-pencil" /></span>
          <span className="badge badge-primary mx-3 pointer" onClick={handleReferralLoad.bind(this, referral.id)}><i className="la la-eye" /></span>
          <span className="badge badge-danger mx-3 pointer" onClick={handleReferralDelete.bind(this, referral.id)}><i className="la la-trash" /></span>
          <span className="badge badge-primary mx-3 pointer" onClick={() => handleReferralResend(referral.id)}><i className="la la-paper-plane" /></span>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = state => {
  const { referral } = state;
  return {
    referrals: referral.referrals
  }
}

export default connect(mapStateToProps)(ReferralListing);