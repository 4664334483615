import React from 'react';
import { addressString, dateFormat, formatPhoneNumber, humanize, filterByKey, numberToCurrency, getDefaultTextLogo } from '../../../../../../components/HelperFunctions';
import rximage from '../../../../../../assets/static/images/bitmap.png';
import DateFormatView from '../../../../../../components/others/DateFormatView';
import { connect } from 'react-redux';

let ReportTemplate = props => {
  const { category, children, displayFields: { header, footer, patient_information }, displayValues: { staff, store, patient }, orderDetails, dateFormatType } = props;
  return (
    <div className="bg-white">
      {/* Report Header */}
      <div className="border-bottom border-bottom-2 border-light px-7 pt-7 pb-6">
        {category !== 'invoice' ?
          <div className={`d-flex ${header.store_logo ? 'justify-content-between' : 'justify-content-end'} `}>
            {header.store_logo &&
              <div className="float-left">
                {getDefaultTextLogo(store, 'width-mx-12 img-fluid', 'py-5')}
              </div>
            }
            <div className="float-right">
              <div className="text-right">
                {header.store_name && <h6 className="mb-2">{store.name}</h6>}
                {header.address && <p className="text-muted">
                  {(store.address.address1 !== null) && `${store.address.address1}, `}
                  {(store.address.address2 !== null) && store.address.address2}
                  <br />
                  {(store.address.city !== null) && `${store.address.city}, `}
                  {(store.address.state !== null) && `${store.address.state} `}
                  {(store.address.zip !== null) && store.address.zip}
                </p>}
                {header.phone_no && <p className="text-muted mb-0"><span className="text-dark font-weight-bold">P- </span> {formatPhoneNumber(store.phone_no)}</p>}
                {header.email && <p className="text-muted mb-0"><span className="text-dark font-weight-bold">E- </span> {store.email}</p>}
                {header.npi && <p className="text-muted mb-0">
                  <span className="text-dark font-weight-bold">NPI- </span> {store.npi}</p>}
                {header.fax && <p className="text-muted">
                  <span className="text-dark font-weight-bold">F- </span>
                  {store.fax}</p>}
                {header.tax_id && <p className="text-muted">{store.tax_id}</p>}
              </div>
            </div>
          </div>
          :
          <div className="">
            {header.store_logo &&
              <div className="mb-7">
                {getDefaultTextLogo(store, 'width-mx-12 img-fluid', 'py-5')}
              </div>
            }
            <div className="">
              <h2 className="mb-5">Invoice</h2>
              <div className="d-flex align-items-center mb-5">
                {orderDetails.date &&
                  <span className="my-3 mr-9"><span>Date: </span><span className="px-4">
                    <DateFormatView date={orderDetails.date} />
                  </span></span>}
                {orderDetails.invoice_id &&
                  <span className="my-3 mx-9"><span>Invoice#: </span><span className="px-4">{orderDetails.invoice_id}</span></span>}
                {orderDetails.payment_status &&
                  <span className="my-3 mx-9"><span>Payment Status: </span><span className="px-4">{humanize(orderDetails.payment_status)}</span></span>}
              </div>
              <div className="row">
                <div className="col-lg-4 col-6">
                  <h5 className="text-info mb-3">BILL FROM</h5>
                  {header.store_name && <h6 className="mb-2">{store.name}</h6>}
                  {header.address && <p className="text-muted">
                    {(store.address.address1 !== null) && `${store.address.address1}, `}
                    {(store.address.address2 !== null) && store.address.address2}
                    <br />
                    {(store.address.city !== null) && `${store.address.city}, `}
                    {(store.address.state !== null) && `${store.address.state} `}
                    {(store.address.zip !== null) && store.address.zip}
                  </p>}
                  {header.phone_no && <p className="text-muted mb-0">{formatPhoneNumber(store.phone_no)}</p>}
                  {header.email && <p className="text-muted mb-0">{store.email}</p>}
                  {header.npi && <p className="text-muted mb-0">{store.npi}</p>}
                  {header.fax && <p className="text-muted">{formatPhoneNumber(store.fax)}</p>}
                  {header.tax_id && <p className="text-muted">{store.tax_id}</p>}
                </div>
                <div className="col-lg-4 col-6">
                  <h5 className="text-info mb-3">BILL TO</h5>
                  {patient_information.patient_name && <h6 className="mb-2">{humanize(patient.name)}</h6>}
                  {patient_information.address && <p className="text-muted">
                    {(patient_information.address.address1 !== null) && `${patient.address && patient.address.address1}, `}
                    {(patient_information.address.address2 !== null) && patient.address && patient.address.address2}
                    <br />
                    {(patient_information.address.city !== null) && `${patient.address && patient.address.city}, `}
                    {(patient_information.address.state !== null) && `${patient.address && patient.address.state} `}
                    {(patient_information.address.zip !== null) && patient.address && patient.address.zip}
                  </p>}
                  {patient_information.phone_no && <p className="text-muted mb-0">{formatPhoneNumber(patient.phone_no)}</p>}
                  {patient_information.email && <p className="text-muted mb-0">{patient.email}</p>}
                  {patient_information.dob && <p className="text-muted mb-0">DOB: <DateFormatView date={patient.dob} /></p>}
                  {patient_information.age && <p className="text-muted mb-0">Age: {patient.age}</p>}
                  {patient_information.gender && <p className="text-muted mb-0">{patient.gender}</p>}
                  {patient_information.ssn && <p className="text-muted mb-0">{patient.ssn}</p>}
                  {patient_information.medical_insurance && <p className="text-muted">{patient.medical_insurance}</p>}
                  {patient_information.medical_number && <p className="text-muted">{patient.medical_number}</p>}
                  {patient_information.vision_insurance && <p className="text-muted">{patient.vision_insurance}</p>}
                  {patient_information.vision_number && <p className="text-muted">{patient.vision_number}</p>}
                </div>
                <div className="col-lg-4 col-6 reports-payment-details">
                  <h5 className="text-info mb-3">PAYMENT DETAILS</h5>
                  {filterByKey(orderDetails.payments, 'payment_type', 'sale').map((payment) =>
                    <div className="d-flex">
                      <strong>Paid:</strong>
                      <p className="d-flex mb-0">
                        <span className="ml-2">
                          <DateFormatView date={payment.payment_date} />
                        </span>
                        <span className="ml-2">
                          {numberToCurrency(payment.amount)}
                        </span>
                        <span className="text-capitalize ml-2">
                          {payment.mode_of_payment}
                        </span>
                      </p>
                    </div>
                  )}

                  {filterByKey(orderDetails.payments, 'payment_type', 'void').map((payment) =>
                    <div className="d-flex">
                      <strong>Void:</strong>
                      <p className="d-flex mb-0">
                        <span className="ml-2">
                          <DateFormatView date={payment.payment_date} />
                        </span>
                        <span className="ml-2">
                          {numberToCurrency(payment.amount)}
                        </span>
                        <span className="text-capitalize ml-2">
                          {payment.mode_of_payment}
                        </span>
                      </p>
                    </div>
                  )}

                  {filterByKey(orderDetails.payments, 'payment_type', 'refund').map((payment) =>
                    <div className="d-flex">
                      <strong>Refund:</strong>
                      <p className="d-flex mb-0">
                        <span className="ml-2">
                          <DateFormatView date={payment.payment_date} />
                        </span>
                        <span className="ml-2">
                          {numberToCurrency(payment.amount)}
                        </span>
                        <span className="text-capitalize ml-2">
                          {payment.mode_of_payment}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      {/* Report Content */}
      {category !== 'invoice' && <div className="p-6">
        {category === 'prescription' && <h5 className="mb-3 text-center">Finalized Prescription</h5>}
        <div className="d-flex flex-wrap">
          {patient_information.patient_name && renderFieldValue("Patient Name:", patient.name)}
          {patient_information.age && renderFieldValue("Age:", patient.age)}
          {patient_information.gender && renderFieldValue("Gender:", patient.gender)}
          {patient_information.dob && renderFieldValue("DOB:", dateFormat(patient.dob, dateFormatType))}
          {patient_information.phone_no && renderFieldValue("Phone:", formatPhoneNumber(patient.phone_no))}
          {patient_information.email && renderFieldValue("Email:", patient.email)}
          {patient_information.ssn && renderFieldValue("SSN:", patient.ssn)}
          {patient_information.medical_insurance && renderFieldValue("Medical Insurance:", patient.medical_insurance)}
          {patient_information.medical_number && renderFieldValue("Medical Number:", patient.medical_number)}
          {patient_information.vision_insurance && renderFieldValue("Vision Insurance:", patient.vision_insurance)}
          {patient_information.vision_number && renderFieldValue("Vision Number:", patient.vision_number)}
          {patient_information.address && <span className="my-3 mr-5"><span>Address: </span><span className="border-bottom border-bottom-2 border-secondary px-4">{addressString(patient.address)}</span></span>}
        </div>
        {category === 'medical_rx' && <div className="ml-8 mt-5"><img src={rximage} alt="rximage" style={{ width: "2.3rem", height: "3rem", objectFit: "contain" }} /></div>}
      </div>}

      {children}

      {/* Report Footer */}
      <div className={`border-top border-top-2 border-light px-7 py-6`}>
        <h6 className="mb-3">Authorized By:</h6>
        {footer.signature &&
          <span className="my-3 mr-5">
            {staff.signature ?
              <span className="px-4">
                <img alt="signature" style={{ maxWidth: "7rem", maxHeight: "6rem" }} className="img-fluid" src={staff.signature && staff.signature.file.url} />
              </span> :
              <em className="py-6 px-5 signature font-size-30">{staff.name}</em>
            }
          </span>
        }
        <div className="d-flex flex-wrap">
          {/* { footer.signature && staff.signature && <img style={{maxWidth:"6rem", maxHeight: "2rem"}} className="img-fluid my-3 mr-5" src={staff.signature && staff.signature.file.url}/> } */}
          {footer.provider_name && <h6 className="my-3 mr-5">{staff.name}</h6>}
          {footer.dea_no && renderFieldValue("DEA #", staff.dea)}
          {footer.lic_no && renderFieldValue("Lic #", staff.lic_no)}
          {footer.npi && renderFieldValue("NPI: ", staff.npi)}
          {footer.email && renderFieldValue("Email: ", staff.email)}
          {footer.phone_no && renderFieldValue("Phone: ", formatPhoneNumber(staff.phone_no))}
          {footer.address && <span className="my-3 mr-5"><span>Address: </span><span className="border-bottom border-bottom-2 border-secondary px-4">{addressString(staff.address)}</span></span>}
        </div>
      </div>
    </div>
  );
}

function renderFieldValue(key, value) {
  return <span className="my-3 mr-5"><span>{key} </span><span className="border-bottom border-bottom-2 border-secondary px-4">{value}</span></span>
}

const mapStateToProps = (state) => {
  const { setting: { orgConfig } } = state;
  return {
    dateFormatType: orgConfig.org_config?.date_format,
  }
}

export default connect(mapStateToProps)(ReportTemplate);