import Toastr from "../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  calling_info: {
    callStatus: '',
    isAnonymous: false,
    isIncomingCall: false,
    isCallActive: false,
  },
  call_pop_up_status: false,
  call_type: '',
  saving: false,
  editing: false,
  notifications: [],
  voip_addon: {}
}
let changes = null;
let notificationsData = []
let allNotifications = []
let updatedRecord = {}

export const voipReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)
  switch (action.type) {

    case 'VOIP_NOTIFICATION_LOAD_PENDING':
      return { ...state, saving: true };

    case 'CALL_POP_UP_EDIT':
      return { ...state, call_pop_up_status: action.id };

    case 'MAKE_OUTGOING_CALL_REJECTED':
    case 'VOIP_ADDON_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = {
        saving: false, calling_info: { isCallActive: false },
      }
      return { ...state, ...changes };

    case 'MAKE_OUTGOING_CALL_FULFILLED':
      let call_log = response['add_ons/voip/call_log']
      changes = {
        calling_info: {
          call_log,
          isAnonymous: !call_log.patient_id,
          isCallActive: true,
        },
        saving: false,
      }
      return { ...state, ...changes };

    case 'INCOMING_CALL':
      changes = {
        calling_info: {
          ...state.calling_info,
          incoming_info: response,
          isAnonymous: !response.patient_id,
          isIncomingCall: true,
          isCallActive: true,
        },
        saving: false,
      }
      return { ...state, ...changes };

    case 'CALL_INFORMATION_UPDATE':
      changes = { calling_info: { ...state.calling_info, ...action.data } }
      return { ...state, ...changes }

    case 'VOIP_INFO_UPDATE':
      changes = { ...action.data }
      return { ...state, ...changes }

    case 'ANONYMOUS_CALL_LOGS_LOAD_FULFILLED':
      changes = { calling_info: { ...state.calling_info, anonymous_call_logs: { ...response } } }
      return { ...state, ...changes }

    case 'VOIP_NOTIFICATION_LOAD_FULFILLED':
      allNotifications = response.meta.messages_read === "All Messages are read" ? [...response.notifications] : [...state.notifications, ...response.notifications]
      changes = { ...response, notifications: [...allNotifications], saving: false };
      return { ...state, ...changes }

    case 'VOIP_NOTIFICATION_UPDATE':
      allNotifications = [...state.notifications];
      updatedRecord = { ...action.data }
      let unreadCount = updatedRecord.read_at ? state.meta.unread_count - 1 : state.meta.unread_count + 1
      for (const [index, notification] of allNotifications.entries()) {
        if (notification.id === updatedRecord.id) {
          notificationsData = [
            ...allNotifications.slice(0, index),
            updatedRecord,
            ...allNotifications.slice(index + 1)
          ];
        }
      }
      changes = {
        notifications: notificationsData,
        meta: { ...state.meta, unread_count: unreadCount },
        saving: false
      };
      return { ...state, ...changes };

    case 'VOIP_NOTIFICATION_CREATE':
      changes = {
        notifications: [action.data.notification, ...state.notifications],
        meta: { ...state.meta, unread_count: state.meta.unread_count + 1 },
        saving: false
      };
      return { ...state, ...changes }

    case 'VOIP_ADDON_LOAD_FULFILLED':
      changes = {
        voip_addon: response.add_ons?.[0],
        saving: false
      };
      return { ...state, ...changes }

    case 'VOIP_EDIT':
      changes = {
        editing: { ...action.data },
      };
      return { ...state, ...changes };

    case 'VOIP_EDIT_CANCEL':
      changes = {
        editing: false
      };
      return { ...state, ...changes };


    default:
      return state
  }
}